import {initialState} from "../reducers/networkTargeting";

const networkValuesMapping = {
  countryTargetingType: 'country_targeting',
  campaignName: 'custom_code',
  groupList: 'group_id',
  platformTargeting: 'platform_targeting',
  trackingType: 'tracking_type',
  publisherTargeting: 'site_rule_set',
  networks: 'networks',
  outbrainMarketer: 'marketer_id',
  geminiAdvertiser: 'account_id',
  'account-list': 'account_id',
  os_targeting: 'os_targeting',
  outbrain_os_targeting: 'outbrain_os_targeting',
  excludeAdBlock: 'excludeAdBlock',
  is_high_impact: 'is_high_impact',
  msn_only: 'msn_only',
  marketing_objective: 'marketing_objective',
  connection_type: 'connection_type',
  accounts: 'accounts',
};

const platformMapping = {
  MOBILE: 'phone',
  PHONE: 'phone',
  PHON: 'phone',
  TBLT: 'tablet',
  TABLET: 'tablet',
  DESK: 'desk',
  DESKTOP: 'desk',
  ALL: 'all',
};

const setStateVal = (value, error = false, message = '') => {
  return {error, message, value};
};

export const formatPlatformTargeting = (value, stateName = 'platformTargeting') => {
  if (stateName === 'platformTargeting') {
    const originalValue = value;
    value = initialState.platformTargeting.value;
    Object.keys(platformMapping).map((targeting) => {
      if(originalValue.includes(targeting)) {
        value[platformMapping[targeting]] = true;
      }
      return true
    })
    if(value.desk && value.phone && value.tablet) {
      value.all = true;
    }
    if (value.all) {
      value = {
        ...value,
        phone: true,
        desk: true,
        tablet: true,
      };
    }
  }
  return value
}

const formatCampaignName = (value, stateName, fieldValues) => {
  if (stateName === 'campaignName') {
    if (!value || value === 'null') {
      value = fieldValues.name.split('_')[1] + '_' + fieldValues.name.split('_')[2];
    }

    value = value ? value : fieldValues.name;

    if(!value.includes('_Copy')) {
      value = `${value}_Copy`
    }
  }
  return value
}

const publisherTargeting = (value, stateName) => {
  if (stateName === 'publisherTargeting') {
    if (!value || value === 'null') {
      value = ' ';
    }
  }
  return value
}

const formatCountryTargeting = (value, stateName, fieldValues) => {
  if (stateName === 'countryTargetingType') {
    value = fieldValues.network_country_targeting ? fieldValues.network_country_targeting : value;
  }
  return value
}

export const handleChange = (state, value, valid = true, message = '') => {
  return {
    state,
    type: 'HANDLE_NETWORK_TARGETING_CHANGE',
    value: {
      value,
      error: !valid,
      message,
    },
  };
};

export const updateNetworks = (value) => {
  return {
    type: 'UPDATE_NETWORKS',
    value,
  };
};

export const resetNetworks = () => {
  return {
    type: 'RESET_NETWORKS_STATE',
  };
};

export const updateNonObjectState = (stateName, value) => {
  return {
    type: 'CHANGE_NON_OBJECT_STATE',
    stateName,
    value,
  };
};

export const setGroupDropdown = (groupDropdown) => {
  return {
    type: 'SET_GROUP_DROPDOWN',
    groupDropdown,
  };
}

export const setAccountsLists = (list, listName) => {
  return {
    type: 'SET_ACCOUNTS_LIST',
    list,
    listName,
  };
}

export const updateNetworkAndTracking = (networks, trackingType) => {
  return {
    type: 'UPDATE_NETWORK_AND_TRACKING',
    networks,
    trackingType,
  };
};

export const loadNetworkValues = (fieldValues) => {
  const newState = {...initialState};

  Object.keys(newState).map(stateName => {
    let value = fieldValues[networkValuesMapping[stateName]];

    value = formatCampaignName(value, stateName, fieldValues)
    value = formatPlatformTargeting(value, stateName);
    value = formatCountryTargeting(value, stateName, fieldValues);
    value = publisherTargeting(value, stateName);

    if (stateName === 'networks' || stateName === 'accounts') {
      newState[stateName] = value;
    } else if (value !== undefined) {
      newState[stateName] = setStateVal(value);
    }

    return true;
  });

  return {
    type: 'LOAD_NETWORK_VALUES',
    newState
  };
};
