import { settings } from '../Settings';
import NetworkRequest from '../utilities/NetworkRequest';
import { getOutbrainMarketersDropdown } from '../components/NetworkTargeting/utilities/networkTargeting';

export const OUTBRAIN_SEGMENTS_GET_OUTBRAIN_CAMPAIGNS = 'OUTBRAIN_SEGMENTS_GET_OUTBRAIN_CAMPAIGNS';
export const OUTBRAIN_SEGMENTS_GET_MARKETERS = 'OUTBRAIN_SEGMENTS_GET_MARKETERS';
export const OUTBRAIN_SEGMENTS_SET_LOADING = 'OUTBRAIN_SEGMENTS_SET_LOADING';

export const setSettingsLoading = (key, value) => ({
  type: OUTBRAIN_SEGMENTS_SET_LOADING,
  loadingKey: key,
  value,
});

export const getOutbrainCampaigns = () => async (dispatch) => {
  dispatch(setSettingsLoading('outbrainCampaignsLoading', true));
  try {
    const { data } = await NetworkRequest.post(
      settings.outbrainSegments,
      { action: 'get_outbrain_campaigns_retargeting' },
    );

    dispatch({
      type: OUTBRAIN_SEGMENTS_GET_OUTBRAIN_CAMPAIGNS,
      value: data.map(({ name, outbrain_id }) => ({ value: outbrain_id, label: name })),
    });
    dispatch(setSettingsLoading('outbrainCampaignsLoading', false));
  } catch (e) {
    console.error(e.message);
    dispatch(setSettingsLoading('outbrainCampaignsLoading', false));
  }
};

export const getMarketersList = () => async (dispatch) => {
  dispatch(setSettingsLoading('marketersLoading', true));
  try {
    const data = await getOutbrainMarketersDropdown();

    dispatch({
      type: OUTBRAIN_SEGMENTS_GET_MARKETERS,
      value: data,
    });
    dispatch(setSettingsLoading('marketersLoading', false));
  } catch (e) {
    console.error(e.message);
    dispatch(setSettingsLoading('marketersLoading', false));
  }
};
