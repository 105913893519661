import {
  OUTBRAIN_SEGMENTS_GET_OUTBRAIN_CAMPAIGNS,
  OUTBRAIN_SEGMENTS_GET_MARKETERS,
  OUTBRAIN_SEGMENTS_SET_LOADING,
} from '../actions/outbrainSegments';

export const segmentTypes = [{
  value: 'Clickers',
  label: 'Clickers',
}];

const initialState = {
  outbrainCampaigns: [],
  outbrainCampaignsLoading: true,
  marketers: [],
  marketersLoading: true,
};

export const outbrainSegments = (state = initialState, action) => {
  switch (action.type) {
    case OUTBRAIN_SEGMENTS_GET_OUTBRAIN_CAMPAIGNS:
      return {
        ...state,
        outbrainCampaigns: action.value,
      };
    case OUTBRAIN_SEGMENTS_GET_MARKETERS:
      return {
        ...state,
        marketers: action.value,
      };
    case OUTBRAIN_SEGMENTS_SET_LOADING:
      return {
        ...state,
        [action.loadingKey]: action.value,
      };
    default:
      return state;
  }
};
