import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, withStyles } from '@material-ui/core';
import maximusPalette from '../MaximusColor';

const styles = () => ({
  submitButton: {
    backgroundColor: maximusPalette.submit,
    color: '#fff',
    marginTop: '3rem',
    marginBottom: '3rem',
  },
  noMarginButton: {
    backgroundColor: maximusPalette.submit,
    color: '#fff',
  },
});

function SubmitButton(props) {
  const content = props.isLoading ?
    <CircularProgress color="inherit" size={25} /> :
    props.content;

  return (
    <Button
      id="submitButton"
      variant="contained"
      color="inherit"
      className={props.noMargin ? props.classes.noMarginButton : props.classes.submitButton}
      onClick={props.handleSubmit}
      disabled={props.isLoading || props.disabled}
    >
      {content}
    </Button>);
}

SubmitButton.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  content: PropTypes.string,
  disabled: PropTypes.bool,
};

SubmitButton.defaultProps = {
  isLoading: false,
  content: 'Submit',
  disabled: false,
  noMargin: false
};

export default withStyles(styles)(SubmitButton);
