import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import { IconButton, Badge, List, Drawer, Typography, withStyles } from '@material-ui/core';
import { ChevronRight, Notifications as Icon } from '@material-ui/icons';
import NetworkRequest from '../utilities/NetworkRequest';
import NotificationsList from './common/NotificationsList';
import { settings } from '../Settings';

const styles = () => ({
  badgeSpan: {
    top: 10,
    right: 10,
  },
  button: {
    color: 'white',
  },
  actionButtons: {
    height: '3rem',
    width: '50%',
    borderRadius: 0,
    backgroundColor: '#343434',
    color: 'white',
    margin: 0,
  },
  buttonsBlock: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'sticky',
    bottom: 0,
    backgroundColor: '#343434',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
  },
  primary: {
    fontWeight: 'bold',
  },
  subheader: {
    backgroundColor: '#343434',
    color: 'white',
    fontSize: '1rem',
    textAlign: 'center',
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
  },
  listContainer: {
    width: 700,
    flex: 'initial',
    maxWidth: '100vw',
    textAlign: 'center',
  },
});

class Notifications extends PureComponent {
  state = {
    active: false,
    notifications: [],
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      const params = {
        action: 'get_user_notifications',
        notifications_limit: 15,
      };
      const { data } = await NetworkRequest.post(settings.notifications, params);
      this.setState({ notifications: data || [] });
    } catch (e) {
      console.error(e.message);
      this.setState({ notifications: [] });
    }
  };

  showList = () => this.setState({ active: true });

  handleClickItem = ({ campaign_name, id, read }) => {
    if (!read) {
      this.markAsRead(id);
    }
  };

  markAsRead = async (messageID = null) => {
    try {
      const notificationsIDs = messageID !== null ?
        [messageID] :
        this.state.notifications.map(notification => notification.id);
      const params = {
        action: 'notification_mark_as_read',
        notification_ids: notificationsIDs,
      };
      this.setState(({ notifications }) => ({
        notifications: notifications.map((notification) => {
          if (notificationsIDs.includes(notification.id)) {
            return {
              ...notification,
              read: 1,
            };
          }
          return notification;
        }),
      }));
      await NetworkRequest.post(settings.notificationsMarkAsRead, params);
    } catch (e) {
      console.error(e.message);
    }
  };

  renderNotificationButton = () => {
    const { classes } = this.props;
    const { notifications } = this.state;
    const unreadAmount = notifications.filter(notification => !notification.read).length;

    const notificationButton = (
      <div id="notificationIndicator" style={{ display: 'inline-block' }}>
        <IconButton
          className={classes.button}
          onClick={this.showList}
        >
          <Icon />
        </IconButton>
      </div>);
    return unreadAmount ?
      <Badge
        color="error"
        badgeContent={unreadAmount}
        classes={{
          badge: classes.badgeSpan,
        }}
      >
        {notificationButton}
      </Badge>
      :
      notificationButton;
  };

  renderNotificationsList = () => {
    const { classes } = this.props;
    const { notifications } = this.state;

    return (
      <div className={classes.listContainer}>
        <List component="nav">
          <NotificationsList
            list={notifications}
            handleClick={this.handleClickItem}
            primaryClass={classes.primary}
          />
        </List>
      </div>
    );
  };

  render() {
    const { active } = this.state;
    return (
      <React.Fragment>
        {!active && this.renderNotificationButton()}
        <Drawer
          anchor="right"
          open={active}
          id="notificationDrawer"
          onClose={() => this.setState({ active: false })}
        >
          <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              <IconButton
                className="icon-button"
                onClick={() => this.setState({ active: false })}
              >
                <ChevronRight />
              </IconButton>
              <Typography variant="h5">Notifications</Typography>
            </div>
            <span
              style={{
                marginRight: 15,
                color: 'gray',
                cursor: 'pointer',
              }}
              onClick={() => this.markAsRead()}
            >
              Mark all as read
            </span>
          </div>
          {this.renderNotificationsList()}
        </Drawer>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Notifications));
