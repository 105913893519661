import { GLANCE_GET_DATA, GLANCE_SET_PROCESSING } from '../actions/dashboard';

const initialState = {
  glanceData: {
    campaigns: 0,
    clicks: 0,
    profit: 0.00,
    conversions: 0,
    revenue: 0,
    spent: 0,
    profitPct: 'NA',
    processing: true,
  },
};

export const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case GLANCE_GET_DATA:
      return {
        ...state,
        glanceData: {
          ...state.glanceData,
          ...action.value,
        },
      };
    case GLANCE_SET_PROCESSING:
      return {
        ...state,
        glanceData: {
          ...state.glanceData,
          processing: action.value,
        },
      };
    default:
      return state;
  }
};
