export const setStateVal = (value = '', error = false, message = '') => ({ error, message, value });

export const loadDefaultAdValues = (file, error, message, carousel = false, title = '', description = '', squareFile = '') => {
  const defaultObj = {
    file: setStateVal(file, error, message),
    squareFile: setStateVal(squareFile),
    title: setStateVal(title),
    description: setStateVal(description),
    callToAction: setStateVal(),
    taboolaCallToAction: setStateVal(),
    video: setStateVal(),
  };
  carousel && delete defaultObj.video;
  return defaultObj;
};

export const getHighestValueForMapping = object =>
  (Object.keys(object).length === 0 ? 0 : Math.max.apply(null, Object.keys(object)) + 1);

/**
 * Example *************
 * ads: {
    0: {
      file: setStateVal(),
      squareFile: setStateVal(),
      title: setStateVal(),
      description: setStateVal(),
      callToAction: setStateVal(),
      video: setStateVal(),
    },
  },
 carouselAds: {
    0: {
      0: {
        file: setStateVal(),
        squareFile: setStateVal(),
        title: setStateVal(),
        description: setStateVal(),
        callToAction: setStateVal(),
      },
    }
  }
 */
export const initialState = {
  copy: false,
  ads: {},
  clonedAds: {},
  carouselAds: {
    0: {},
  },
  badImages: [],
  imageErrorDialogue: false,
  videoUpload: false,
  carouselAdsNumber: 1,
  'rc-targeting': setStateVal('Topic'),
  rc_language: setStateVal('1'),
  allocation_even: setStateVal(1),
  showCarousel: setStateVal(false),
  brandingText: setStateVal(),
  displayUrl: setStateVal(),
  imagesToClone: setStateVal([]),
  errorMessage: '',
  userSettings: {
    carousel: true,
    callToAction: true,
    translateButton: true,
    revcontentAlert: false,
    notifications: true,
    geminiHighCPCAlert: true,
    tutorialVideos: false,
  },
};

export const adSetup = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_AD_CHANGE':
      return {
        ...state,
        [action.state]: action.value,
      };
    case 'ADD_AD':
      const amountOfAds = getHighestValueForMapping(state.ads);
      return {
        ...state,
        [action.adType]: Object.assign({}, state[action.adType], {
          [amountOfAds]: loadDefaultAdValues(
            action.value, action.error, action.message, false,
            action.title, action.description, action.squareFile,
          ),
        }),
      };
    case 'CHANGE_AD':
      return {
        ...state,
        [action.adType]: Object.assign({}, state[action.adType], {
          [action.key]: Object.assign(
            {}, state[action.adType][action.key],
            {
              [action.stateName]: action.value,
            },
          ),
        }),
      };
    case 'REMOVE_AD':
      const ads = Object.assign({}, state[action.adType]);
      delete ads[action.key];
      return {
        ...state,
        [action.adType]: ads,
      };
    case 'IMAGE_ERROR':
      let { badImages } = Object.assign([], state);
      action.value.status ?
        badImages.push({ url: action.value.url, errorMsg: action.value.errorMessage }) :
        badImages = [];
      return {
        ...state,
        imageErrorDialogue: action.value.status,
        badImages,
      };
    case 'TOGGLE_VIDEO_UPLOAD':
      return {
        ...state,
        videoUpload: !state.videoUpload,
      };
    case 'CHANGE_CAROUSEL_NUMBER':
      const carouselAds = Object.assign({}, state.carouselAds);
      for (let i = 0; i < action.value.value; i++) {
        if (!carouselAds[i]) {
          carouselAds[i] = {};
        }
      }
      Object.keys(carouselAds).map((key) => {
        if (key >= action.value.value) {
          delete carouselAds[key];
        }
        return true;
      });
      return {
        ...state,
        carouselAds,
        carouselAdsNumber: action.value,
      };
    case 'UPLOAD_CAROUSEL_AD':
      const carouselAd = Object.assign({}, state.carouselAds[action.carouselID]);
      const newAdKey = getHighestValueForMapping(carouselAd);
      return {
        ...state,
        carouselAds: Object.assign({}, state.carouselAds, {
          [action.carouselID]: Object.assign({}, carouselAd, {
            [newAdKey]: loadDefaultAdValues(action.value, action.error, action.message),
          }),
        }),
      };
    case 'REMOVE_CAROUSEL_AD':
      const carouselAdObj = Object.assign({}, state.carouselAds[action.carouselID]);
      delete carouselAdObj[action.key];
      return {
        ...state,
        carouselAds: Object.assign({}, state.carouselAds, {
          [action.carouselID]: carouselAdObj,
        }),
      };
    case 'CAROUSEL_CHANGE_AD':
      return {
        ...state,
        carouselAds: Object.assign({}, state.carouselAds, {
          [action.carouselID]: Object.assign({}, state.carouselAds[action.carouselID], {
            [action.key]: Object.assign({}, state.carouselAds[action.carouselID][action.key], {
              [action.stateName]: action.value,
            }),
          }),
        }),
      };
    case 'ADD_CLONED_AD':
      const amountOfClonedAds = getHighestValueForMapping(state.clonedAds);
      return {
        ...state,
        clonedAds: Object.assign({}, state.clonedAds, {
          [amountOfClonedAds]: action.value,
        }),
      };
    case 'ADD_CLONED_CAROUSEL_AD':
      let carouselAdObject = Object.assign({}, state.carouselAds);
      if (!carouselAdObject[action.carouselID]) {
        Object.assign(carouselAdObject, {
          [action.carouselID]: {},
        });
      }
      carouselAdObject = Object.assign({}, carouselAdObject[action.carouselID]);
      const newAdKeyNumber = getHighestValueForMapping(carouselAdObject);
      return {
        ...state,
        carouselAds: Object.assign({}, state.carouselAds, {
          [action.carouselID]: Object.assign({}, carouselAdObject, {
            [newAdKeyNumber]: action.value,
          }),
        }),
      };
    case 'RESET_CAROUSEL_ADS':
      return {
        ...state,
        carouselAds: {
          0: {},
        },
      };
    case 'LOAD_USER_SETTINGS':
      return {
        ...state,
        userSettings: { ...action.value },
      };
    case 'SET_USER_SETTING':
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          [action.name]: action.value,
        },
      };
    case 'RESET_AD_SETUP':
      return action.newState;
    case 'LOAD_AD_SETUP':
      return action.newState;
    default:
      return state;
  }
};
