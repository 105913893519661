import { post } from '../../../utilities/NetworkRequest';
import { settings } from '../../../Settings';
import ContentAdLogo from '../../../assets/images/content_ad.png';
import OutbrainLogo from '../../../assets/images/Outbrainlogo.png';
import RevContentLogo from '../../../assets/images/revcontent.png';
import TaboolaLogo from '../../../assets/images/taboola.png';
import GeminiLogo from '../../../assets/images/gemini.png';

const TABOOLA = 1;
const OUTBRAIN = 2;
const REVCONTENT = 3;
const CONTENTAD = 4;
const GEMINI = 5;
const networks = {
  TABOOLA,
  OUTBRAIN,
  REVCONTENT,
  CONTENTAD,
  GEMINI,
};

export const logos = {
  taboola: TaboolaLogo,
  outbrain: OutbrainLogo,
  revcontent: RevContentLogo,
  contentad: ContentAdLogo,
  gemini: GeminiLogo,
};

const RADIX = 10;

export const getUserNetworks = async () => {
  const userNetworks = await post(settings.getUserNetworks);
  return {
    networks: (Object.keys(userNetworks) || [])
      .filter(network => userNetworks[network] !== true && userNetworks[network].length !== 0)
      .map(userNetwork => parseInt(userNetwork, RADIX)),
    accounts: userNetworks || {},
  };
};

const hasNetwork = (userNetworks, network) => userNetworks.includes(network);
export const hasTaboola = userNetworks => hasNetwork(userNetworks, TABOOLA);
export const hasOutbrain = userNetworks => hasNetwork(userNetworks, OUTBRAIN);
export const hasRevcontent = userNetworks => hasNetwork(userNetworks, REVCONTENT);
export const hasContentad = userNetworks => hasNetwork(userNetworks, CONTENTAD);
export const hasGemini = userNetworks => hasNetwork(userNetworks, GEMINI);

export const getGroupDropdown = async () => post(settings.getGroupsDropdown);

export const getGeminiAdvertisersDropdown = async usmID => post(settings.gemini, { action: 'get_gemini_advertiser_ids', usm_id: usmID });

export const getOutbrainMarketersDropdown = async usmID => post(settings.getOutbrainAccounts, {
  usm_id: usmID,
});

export const getTaboolaAccountsDropdown = async usmID => post(settings.dataURL, { action: 'get_taboola_accounts', usm_id: usmID });

export default networks;
