import {each} from 'lodash';
import axios from 'axios';
import {initialState} from "../Reducers/CampaignDetails";
import { getDataURL, settings } from '../../../Settings';
import {formatCampaignStatus} from "../Utilities/formatCampaignStatus";
import NetworkRequest from '../../../utilities/NetworkRequest';
import {
  formatPlatformTargeting,
  handleChange as updateNetworkTargeting,
} from '../../NetworkTargeting/actions/networkTargeting';

export const RESET_CAMPAIGN_DETAILS = 'RESET_CAMPAIGN_DETAILS';
export const UPDATE_DATE_RANGE = 'UPDATE_DATE_RANGE';
export const UPDATE_CAMPAIGN_VALUE = 'UPDATE_CAMPAIGN_VALUE';
export const LOAD_GROUPS = 'LOAD_GROUPS';
export const LOAD_USERS = 'LOAD_USERS';
export const UPDATE_RULES_STATUS = 'UPDATE_RULES_STATUS';
export const UPDATE_HIDE_BLOCKED_SITES_STATUS = 'UPDATE_HIDE_BLOCKED_SITES_STATUS';

export const updateCampaignValue = (state, value) => async (dispatch) => {
  const values = {
    type: UPDATE_CAMPAIGN_VALUE,
    value,
    state
  };

  dispatch(values);
  return values;
};

export const updateDateRange = (start_date, end_date, date_range, week_days) => {
  return {
    type: UPDATE_DATE_RANGE,
    start_date,
    end_date,
    date_range,
    week_days,
  };
};

export const updateHideBlockedSites = (value) => {
  return {
    type: UPDATE_HIDE_BLOCKED_SITES_STATUS,
    value,
  };
};

export const resetCampaignValues = () => {
  const newState = {...initialState};
  return {
    type: UPDATE_CAMPAIGN_VALUE,
    newState
  };
};

export const getGroupsForDropDown = () => async (dispatch) => {
  try {
    const {data: fields = []} = await axios.post(settings.getGroupsDropdown, {
      params: {
        token: localStorage.getItem('token'),
      }
    });

    const groups = {};

    each(fields, (obj) => {
      groups[obj.g_id] = obj.name;
      return true;
    });

    dispatch({
      type: LOAD_GROUPS,
      groups: Object.assign({}, groups),
    });
  } catch (e) {
    console.error(e)
  }
}

export const getUsersForDropDown = () => async (dispatch) => {
  try {
    const { data } = await axios.post(settings.userList, {
      params: {
        token: localStorage.getItem('token'),
        key: 'id',
        value: 'user_fullname',
      }
    });

    dispatch({
      type: LOAD_USERS,
      users: data.data,
    });
  } catch (e) {
    console.error(e)
  }
}

export const getCampaignStatus = (campaignName, params = {}) => async (dispatch) => {
  params['token'] = localStorage.getItem('token');
  params['action'] = 'get_campaign_status';
  params['campaign_name'] = campaignName;

  axios.post(getDataURL(), {params})
    .then(({data}) => {
      if (data !== undefined) dispatch(updateCampaignValue('campaignStatus', formatCampaignStatus(data)));
    })
    .catch((e) => console.error(e));
}

export const getFireAndForgetFlag = (campaign_name, cpc) => async (dispatch) => {
  const params = {
    token: localStorage.getItem('token'),
    campaign_name,
  };

  axios.post(settings.ffMessage, {params})
    .then(({data}) => {
      dispatch(updateCampaignValue('fireAndForgetFlag', data.data.message));
    })
    .catch((e) => console.error(e));
}

export const getCampaignStatusText = (campaign_name) => async (dispatch) => {
  const {data: response} = await axios.post(settings.approvedState, {
    params: {
      token: localStorage.getItem('token'),
      campaign_name
    }
  });
  const {
    warning_status: status = false,
    campaign_status: campaignStatusText = '',
  } = response.data || {};
  if (status) dispatch(updateCampaignValue('campaignStatusText', campaignStatusText));
}

export const getRulesStatus = (campaign_name) => async (dispatch) => {
  const params = {
    token: localStorage.getItem('token'),
    action: 'get_rules_status',
    campaign_name: campaign_name,
  }

  axios.post(settings.rules, {params})
    .then(({data}) => {
      if (data.success !== undefined) dispatch(updateCampaignValue('rulesStatus', data.data.status));
    })
    .catch((e) => console.error(e));
}

export const getGeminiDisplayURL = (campaign_id, conditional) => async (dispatch) => {
  if (!conditional) return;

  const params = {
    token: localStorage.getItem('token'),
    action: 'get_display_url',
    campaign_id: campaign_id,
  }

  axios.post(getDataURL(), {params})
    .then(({data}) => {
      if (data === undefined) return;
      dispatch(updateCampaignValue('display_url', data));
    })
    .catch((e) => console.error(e));
}

export const getActiveBidsCount = (campaign_name, conditional = true) => async (dispatch) => {
  if (!conditional) return;

  const params = {
    token: localStorage.getItem('token'),
    action: 'get_amount_bid_modifiers',
    campaign_name: campaign_name,
  };

  axios.post(getDataURL(), {params})
    .then(({data}) => {
      if (data === undefined) return;
      dispatch(updateCampaignValue('bidsCount', data));
    })
    .catch((e) => console.error(e));
}

export const getBlockedGeminiDomains = campaignID => async (dispatch) => {
  const params = {
    action: 'get_site_block_count',
    campaign_id: campaignID,
  };

  try {
    const { data } = await NetworkRequest.post(settings.gemini, params);
    if (data === undefined) return;
    dispatch(updateCampaignValue('blockedDomainsCount', data));
  } catch (e) {
    console.error(e.message);
  }
};

export const getNetworkIDAndCampaignID = (campaign_name) => async (dispatch) => {
  const params = {
    token: localStorage.getItem('token'),
    action: 'get_network_and_campaign_id',
    campaign_name: campaign_name,
  };

  return await axios.post(settings.campaignData, {params})
    .then(({data}) => {
      if (data !== undefined) {
        dispatch(updateCampaignValue('load_error', data.length === 0));
        data.network_id = parseInt(data.network_id, 10);
        dispatch(updateCampaignValue('network', data.network_id));
        dispatch(updateCampaignValue('networks', [data.network_id]));
        dispatch(updateCampaignValue('campaign_id', data.campaign_id));
        dispatch(updateCampaignValue('tracking_type', data.tracking_type));
        dispatch(updateCampaignValue('budgetIncreaseMode', data.outbrain_budget_increase === '1'));
      }
    })
    .catch((e) => console.error(e));
}

export const getActiveItemsCount = (campaign_name) => async (dispatch) => {
  const params = {
    token: localStorage.getItem('token'),
    action: 'get_active_items_count',
    campaign_name: campaign_name,
  }
  axios.post(settings.activeItemsCount, {params})
    .then(({data:response}) => {
      if (response.data.active_images === undefined) return;
      dispatch(updateCampaignValue('items_count', response.data.active_images || 0));
    })
    .catch((e) => console.error(e));
}

export const getInactiveStatus = (campaign_name) => async (dispatch) => {
  const params = {
    token: localStorage.getItem('token'),
    campaign_name,
  };

  axios.post(settings.rulesInactive, {params})
    .then(({data: response}) => {
      dispatch(updateCampaignValue('inactiveStatus', response.data.status));
    })
    .catch((e) => {
      console.error(e)
    });
}

export const getOfferLink = (campaign_name) => async (dispatch) => {
  let params = {
    token: localStorage.getItem('token'),
    campaign_name
  }

  axios.post(settings.offerLinks, {params})
    .then(({data: response}) => {
      if (response.data.uuid !== undefined) {
        dispatch(updateCampaignValue('link', response.data.link));
        dispatch(updateCampaignValue('link_uuid', response.data.uuid));
      }
    })
    .catch((e) => console.error(e));
}

export const getArchiveStatus = (campaignName) => async (dispatch) => {
  const params = {
    token: localStorage.getItem('token'),
    action: 'get_archive_status',
    campaign_name: campaignName
  };

  axios.post(settings.archiveStatus, {params})
    .then(({data}) => {
      const archiveStatus = ((data.data.toString()) === '0')
      dispatch(updateCampaignValue('campaignArchiveStatus', archiveStatus));
    })
    .catch((e) => console.error(e));
}

export const updateTopPerformingImagesData = (campaign_id, network) => async (dispatch) => {
  axios.post(settings.topPerfomanceImages, {
    params: {
      action: 'get_top_performing_ads',
      network,
      campaign_id,
      token: localStorage.getItem('token'),
    }
  })
    .then(({data}) => dispatch(updateCampaignValue('campaign_images', data)))
    .catch((e) => console.error(e));
}

export const getCampaignClicks = (campaign_name, tracking_type) => async (dispatch) => {
  try {
    const {data} = await axios.post(getDataURL(), {
      params: {
        token: localStorage.getItem('token'),
        action: 'check_clicks_rules_applied',
        campaign_name,
        tracking_type,
      }
    })
    dispatch(updateCampaignValue('fnfMinClicksReached', data))
  } catch (e) {
    console.error(e);
  }
}

export const getTestingLinksStatus = campaignName => async (dispatch) => {
  try {
    const { data } = await NetworkRequest.post(
      settings.linkTestStatus,
      {
        campaign_name: campaignName,
      },
    );
    dispatch(updateCampaignValue('testingLinksStatus', data.data.status));
  } catch (e) {
    console.error(e);
  }
};

export const getAdvancedTrackingStatus = (campaignID, networkID) => async (dispatch) => {
  try {
    const { data } = await NetworkRequest.post(
      settings.campaign,
      {
        action: 'get_advanced_tracking',
        campaign_id: campaignID,
        network_id: networkID,
      },
    );
    dispatch(updateCampaignValue('advancedTrackingCampaign', data));
  } catch (e) {
    console.error(e);
  }
};

export const getAutomatedRoutesStatus = (campaignName, networkID) => async (dispatch) => {
  try {
    const { data } = await NetworkRequest.post(
      settings.routing,
      {
        action: 'get',
        campaign_name: campaignName,
        network_id: networkID,
      },
    );
    if (data && data.data) {
      dispatch(updateCampaignValue('automatedRoutesStatus', !!+data.data.status));
      dispatch(updateCampaignValue('automatedRoutesPercentageCVR', +data.data.percentage_cvr));
      dispatch(updateCampaignValue('automatedRoutesPercentageCTR', +data.data.percentage_ctr));
      dispatch(updateCampaignValue('automatedRoutesClicks', +data.data.sample_size));
    }
  } catch (e) {
    console.error(e);
  }
};

export const getSiteBlockLimit = (campaign_id, network_id) => async (dispatch) => {
  const params = {
    action: 'site_blocking_limit_reached',
    campaign_id,
    network_id,
  };

  const { data } = await NetworkRequest.post(getDataURL(), params);
  dispatch(updateCampaignValue('siteBlockLimitReached', data));
};

export const getGeminiSiteBlock = campaignID => async (dispatch) => {
  const params = {
    action: 'get_site_block_threshold',
    campaign_id: campaignID,
  };

  const { data } = await NetworkRequest.post(settings.gemini, params);
  dispatch(updateCampaignValue('geminiSiteBlockLimitReached', data));
};

export const getOutbrainBids = (campaign_name) => async (dispatch) => {
  const params = {
    campaign_name,
  };

  const { data } = await NetworkRequest.post(settings.getOutbrainSiteBidsMax, params);
  dispatch(updateCampaignValue('maxBidsOutbrain', data.data.count));
};

export const getCPAGoalReached = (campaign_name) => async (dispatch) => {
  const params = {
    campaign_name,
  };
  const { data } = await NetworkRequest.post(settings.getCpaGoal, params);
  dispatch(updateCampaignValue('CPAGoalReached', data.success));
  if (data.success) {
    dispatch(updateCampaignValue('CPAGoalReachedMessage', data.data));
  }
};

export const getLinkTestingBanner = campaignName => async (dispatch) => {
  const params = {
    action: 'notification',
    campaign_name: campaignName,
  };
  const { data } = await NetworkRequest.post(settings.linkTestNotification, params);
  dispatch(updateCampaignValue('linkTestingNotification', data.success));
  dispatch(updateCampaignValue('testingLinksStatus', !!data.message));
  if (data.success) {
    dispatch(updateCampaignValue('linkTestingNotificationMessage', data.message));
    dispatch(updateCampaignValue('linkTestingClicks', data.total_test_clicks));
    dispatch(updateCampaignValue('link_uuid', data.link_id));
  }
};

export const getAutomatedRoutesBanner = campaignName => async (dispatch) => {
  const params = {
    action: 'get_stripe',
    campaign_name: campaignName,
  };
  const { data } = await NetworkRequest.post(settings.routing, params);
  if (data && data.success && data.data) {
    dispatch(updateCampaignValue('automatedRoutesMessage', data.data.message));
  }
};

export const getPlatformTargeting = (campaignName, networkID) => async (dispatch) => {
  const params = {
    action: 'get_platform_targeting',
    campaign_name: campaignName,
    network_id: networkID,
  };
  const { data } = await NetworkRequest.post(settings.dataURL, params);
  if (data) {
    const newPlatforms = formatPlatformTargeting(data);
    dispatch(updateNetworkTargeting('platformTargeting', newPlatforms));
  }
};
