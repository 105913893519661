import {combineReducers} from 'redux';
import {users} from './users';
import {table} from './table';
import {navigation} from './navigation';
import {groups} from './groups';
import { dashboard } from './dashboard';
import {addCampaign} from "../components/AddCampaigns/reducers/addCampaign";
import {geminiSettings} from "../components/GeminiSettings/reducers/geminiSettings";
import {networkTargeting} from "../components/NetworkTargeting/reducers/networkTargeting";
import {createLink} from "../components/CreateLink/reducers/createLink"
import {campaignDetails} from "../components/CampaignDetails/Reducers/CampaignDetails"
import {adSetup} from "../components/AdSetup/reducers/adSetup";
import {fireAndForget} from "../components/Fire&Forget/reducers/Fire&Forget";
import {budget} from "../components/Budget/reducers/budget";
import {notifications} from '../containers/Notifications/reducers';
import { outbrainSegments } from './outbrainSegments';
import {SiteBids} from "../components/AddCampaigns/SiteBids/reducers/reducers";

export default combineReducers({
  users,
  groups,
  navigation,
  addCampaign,
  geminiSettings,
  networkTargeting,
  createLink,
  adSetup,
  fireAndForget,
  budget,
  notifications,
  dashboard,
  campaignDetails,
  outbrainSegments,
  table,
  SiteBids
});
