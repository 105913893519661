import { currencyValidation } from '../../../utilities/currencyHelper';

const setStateVal = (value, error = false, message = '') => ({ error, message, value });

export const initialState = {
  spending_limit: setStateVal(currencyValidation(200)),
  cpc: setStateVal(currencyValidation(0.05)),
};

export const budget = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_BUDGET_CHANGE':
      return {
        ...state,
        [action.state]: action.value,
      };
    default:
      return state;
  }
};
