import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _noop from 'lodash/noop';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel, Dialog as ModalDialog } from '@material-ui/core';
import maximusPalette from '../../MaximusColor';
import './Dialog.css';
import ConfirmDialog from './ConfirmDialog';
import SuccessDialog from './SuccessDialog';
import ErrorDialog from './ErrorDialog';

export default class Dialog extends Component {
  static propTypes = {
    open: PropTypes.bool,
    preventable: PropTypes.bool,
    closeIcon: PropTypes.bool,
    disableOnCheck: PropTypes.bool,
    checkboxMessage: PropTypes.string,
    saveButtonText: PropTypes.string,
    cancelButton: PropTypes.bool,
    cancelButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string,
    type: PropTypes.oneOf(['confirm', 'success', 'error', '']),
    titleAlign: PropTypes.oneOf(['center', 'left', 'right']),
    contentAlign: PropTypes.oneOf(['center', 'left', 'right']),
    footerAlign: PropTypes.oneOf(['center', 'left', 'right']),
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xlg']),
    className: PropTypes.string,
    classes: PropTypes.shape({}),
    shouldRenderAction: PropTypes.bool,
    onRequestSave: PropTypes.func,
    onRequestClose: PropTypes.func,
    title: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string,
    ]),
    children: PropTypes.node,
  };

  static defaultProps = {
    type: '',
    open: false,
    preventable: false,
    closeIcon: true,
    disableOnCheck: false,
    checkboxMessage: null,
    title: null,
    titleAlign: 'center',
    contentAlign: 'center',
    footerAlign: 'right',
    size: 'md',
    className: '',
    shouldRenderAction: true,
    onRequestSave: _noop,
    onRequestClose: _noop,
    children: null,
    saveButtonText: 'Save',
    cancelButton: true,
    overrideOpen: false,
    cancelButtonText: 'Cancel',
    confirmButtonText: 'Close',
    disabled: false,
    classes: {},
  };

  state = {
    open: this.props.open,
    isChecked: false,
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.open !== nextProps.open) {
      this.toggleOpenModal(nextProps.open, true);
    }
  }

  onSuccess = () => Promise
    .resolve(this.props.onRequestSave({ isChecked: this.state.isChecked }))
    .then(() => this.toggleOpenModal(false));

  onCancel = () => Promise
    .resolve(this.props.onRequestClose({ isChecked: this.state.isChecked }))
    .then(() => this.toggleOpenModal(false));

  toggleOpenAnimation = (filter, value) =>
    this.setState({ [filter]: value });

  toggleOpenModal = (open = true, parentProps = false) => {
    if(!this.props.overrideOpen || parentProps) {
      this.setState({ open });
    }
  }

  defaultInnerDialog = () => {
    const {
      children,
      preventable,
      disableOnCheck,
      checkboxMessage,
      contentAlign,
      footerAlign,
      saveButtonText,
      cancelButton,
      cancelButtonText,
      shouldRenderAction,
    } = this.props;

    return (
      <div>
        <div className="maximus-dialog-content" style={{ 'text-align': contentAlign }}>
          <div>{ children }</div>
          {(preventable || disableOnCheck) && (
            <div className="maximus-dialog-checkbox">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isChecked}
                    onChange={event => this.setState({ isChecked: event.target.checked })}
                    value="isChecked"
                    color="primary"
                  />
                }
                label={checkboxMessage}
              />
            </div>
          )}
        </div>

        {shouldRenderAction && (
          <div className={cn('maximus-dialog-footer', footerAlign)}>
            {cancelButton &&
            <Button
              onClick={this.onCancel}
              variant="contained"
              style={{
                backgroundColor: maximusPalette.darkCancel,
                color: 'white',
                minWidth: '40%',
                margin: '0 5%',
              }}
            >
              {cancelButtonText}
            </Button>}
            <Button
              onClick={this.onSuccess}
              variant="contained"
              style={{
                backgroundColor: maximusPalette.submit,
                color: 'white',
                minWidth: '40%',
                margin: '0 5%',
              }}
            >
              {saveButtonText}
            </Button>
          </div>
        )}
      </div>
    );
  };

  renderInnerDialogContent = () => {
    let innerDialog;

    switch (this.props.type) {
      case 'confirm':
        innerDialog = <ConfirmDialog {...this.props} toggleOpenModal={this.toggleOpenModal} />;
        break;
      case 'success':
        innerDialog = <SuccessDialog {...this.props} toggleOpenModal={this.toggleOpenModal} />;
        break;
      case 'error':
        innerDialog = <ErrorDialog {...this.props} toggleOpenModal={this.toggleOpenModal} />;
        break;
      default:
        // if type isn't specified, render original inner dialog content for now
        innerDialog = this.defaultInnerDialog();
    }
    return innerDialog;
  };

  render() {
    const { entering, exiting, open } = this.state;
    const {
      classes,
      size,
      className,
      title,
      titleAlign,
    } = this.props;

    return (
      <ModalDialog
        classes={classes}
        open={open}
        onClose={this.onCancel}
        BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.64)' } }}
        onExit={() => this.toggleOpenAnimation('exiting', true)}
        onExited={() => this.toggleOpenAnimation('exiting', false)}
        PaperProps={{ className: cn('maximus-dialog-paper', size, className, { entering, exiting }) }}
      >
        {title &&
          <div className="maximus-dialog-title" style={{ textAlign: titleAlign }}>
              {title}
          </div>}
        {this.renderInnerDialogContent()}
      </ModalDialog>
    );
  }
}
