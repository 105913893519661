import FieldData from '../utilities/FieldData';

const today = new Date().toISOString().split("T");

const setStateVal = (value = '', error = false, message = '') => {
  return {value, error, message};
};

export const initialState = {
  networks: [],
  campaignName: setStateVal(),
  startDate:setStateVal(today[0]),
  countryTargetingType: setStateVal(['US']),
  publisherTargeting: setStateVal(' '),
  countryTargeting: setStateVal(),
  groupList: setStateVal(),
  trackingType: setStateVal('CPA'),
  outbrainMarketer: setStateVal(),
  geminiAdvertiser: setStateVal(),
  'account-list': setStateVal(),
  os_targeting: setStateVal('All'),
  outbrain_os_targeting: setStateVal('all'),
  // extendedNetworkTraffic: setStateVal(false),
  excludeAdBlock: setStateVal(false),
  is_high_impact: setStateVal(false),
  msn_only: setStateVal(false),
  connection_type: setStateVal(0),
  marketing_objective: setStateVal(new FieldData().marketingObjective[0].value),
  gemini_objective: setStateVal('0'),
  gemini_language: setStateVal('en'),
  groupDropdown: [],
  taboolaAccountsList: [],
  outbrainAccountsList: [],
  geminiAccountsList: [],
  userNetworks: [],
  networksAccounts: {},
  accounts: {},
  platformTargeting: setStateVal({all: false, desk: false, phone: false, tablet: false}),
  multiCampaigns: setStateVal(false),
  copyBidsCampaign: setStateVal(null),
};

export const networkTargeting = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_NETWORK_TARGETING_CHANGE':
      return {
        ...state,
        [action.state]: action.value
      };
    case 'CHANGE_NON_OBJECT_STATE':
      return {
        ...state,
        [action.stateName]: action.value
      };
    case 'UPDATE_NETWORKS':
      return {
        ...state,
        networks: action.value
      };
    case 'UPDATE_NETWORK_AND_TRACKING':
      return {
        ...state,
        networks: action.networks,
        trackingType: setStateVal(action.trackingType),
      };
    case 'SET_GROUP_DROPDOWN':
      return {
        ...state,
        groupDropdown: action.groupDropdown
      };
    case 'SET_ACCOUNTS_LIST':
      return {
        ...state,
        [action.listName] : action.list
      };
    case 'RESET_NETWORKS_STATE':
      return initialState;
    case 'LOAD_NETWORK_VALUES':
      return action.newState;
    default:
      return state;
  }
};
