import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import {TextField, Button, Grid, Typography} from '@material-ui/core';
import maximusHelmet from '../assets/images/Maximus_Icon1.png';
import maximuslogo from '../assets/images/Maximus_Logo2.png';
import maximusBG from '../assets/images/maximus_login_bg.jpeg';
import _ from 'lodash';
import axios from 'axios';
import {settings} from '../Settings.js';
import {DialogContent, DialogContentText} from '@material-ui/core';
import Dialog from '../components/common/dialog/Dialog';
import {getFeatureFlags} from "../utilities/FeatureFlagHelper";
import {redirectNewUser} from "../utilities/redirectNewUsers";
import SubmitButton from "./common/SubmitButton";

const propTypes = {
  classes: PropTypes.object.isRequired
};

const styles = (theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '20rem',
  },
  body: {
    backgroundColor: 'red',
  },
  loginFormContainer: {textAlign: 'center'},
  loginForm: {marginBottom: '1rem'},
  helmet: {margin: '0 auto', width: '8rem', marginBottom: '2rem'},
  logo: {margin: '0 auto', width: '30rem', maxWidth: '80%'},
  tagLine: {
    fontSize: "1.75rem",
    color: "white",
    fontWeight: "100",
  },
  forgotPasswordButton: {textAlign: 'center'},
  loginLeftContainer: {
    backgroundImage: `url(${maximusBG})`,
    backgroundColor: '#333',
    height: '100%',
    boxShadow: '0 0 12px -1px'

  },
  forgotPasswordButtonDialog: {
    cursor: 'pointer',
    color: 'blue',
    border: 'none',
    backgroundColor: 'transparent',
    padding: '0 5px',
    fontSize: '1rem',
  },
}));

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      loggedIn: false,
      submitting: false,
      showForgotPassword: false,
      forgotPasswordEmail: '',
      forgotPasswordEmailError: false,
      forgotPasswordEmailErrorMessage: '',
      alertOpen: false,
      alertMessage: '',
      alertTitle: '',
      alertType: 'error',
      failedLoginAttempts: 0,
      totalFailedLoginAttempts: 0,
    };
  }

  componentDidMount() {
    this.submitOnEnter();
  }

  componentWillUnmount() {
    this.clearListener();
  }

  submitOnEnter = () => {
    const loginPage = document.querySelector('#loginPage');
    if (!loginPage) {
      return false;
    }
    loginPage.addEventListener('keypress', (object) => {
      console.log('listening')
      const enter = 13;
      const key = object.which || object.keyCode;
      if (key === enter) {
        this.handleLogin();
      }
    })
  }

  clearListener = () => {
    const loginPage = document.querySelector('#loginPage');
    if (!loginPage) {
      return false;
    }
    loginPage.removeEventListener('keypress', () => {
    });
  }

  handleChange = (state, value) => this.setState({[state]: value})

  showForgotPassword = () =>
    this.setState({
      showForgotPassword: true,
      alertOpen: false,
    });

  handleDialogRequestCloseDeny = () => this.setState({showForgotPassword: false});

  handleAlertRequestClose = () => this.setState({alertOpen: false});

  handleDialogRequestCloseAccept = () => {
    this.setState({showForgotPassword: false}, function () {
      this.submitForgotPassword();
    });
  };

  handleLogin = () => {
    const {email, password} = this.state;
    this.setState({submitting: true});
    axios.post(settings.login, {
      email,
      password,
    }).then((response) => {
      if (response.data.status === false) {
        // API has returned an error
        this.setState({
          alertOpen: true,
          alertMessage: response.data.message,
          alertType: 'error',
          alertTitle: 'Error',
          submitting: false,
        }, this.checkFailedAttempts);
      } else {
        // TODO needs error checking here
        localStorage.setItem('token', JSON.stringify(response.data.token));
        localStorage.setItem('email', email);
        localStorage.setItem('user_filter', 'all_users');
        getFeatureFlags();

        redirectNewUser(window.location.pathname)
        this.props.getAuthData();
        this.setState({'loggedIn': true, submitting: false});
      }
    }).catch(() => {
      this.setState({ submitting: false }, this.checkFailedAttempts);
    });
  };

  checkFailedAttempts = () => {
    this.setState(({ failedLoginAttempts, totalFailedLoginAttempts }) =>
      ({
        failedLoginAttempts: failedLoginAttempts + 1,
        totalFailedLoginAttempts: totalFailedLoginAttempts + 1,
      }),() => {
      if (this.state.failedLoginAttempts === 5 || this.state.totalFailedLoginAttempts === 15) {
        this.setState({
          maxAttemptsReached: true,
          alertOpen: true,
          alertMessage: this.state.totalFailedLoginAttempts === 15 ?
            this.renderManyAttemptsText() :
            'Too many login attempts in a row. Please wait a few seconds before attempting to login again.',
          alertType: 'error',
          alertTitle: 'Error',
          failedLoginAttempts: 0,
        }, () => setTimeout(() => this.setState({
          maxAttemptsReached: false,
          alertOpen: false,
        }), 10000));
      }
    });
  };

  validateForgotPasswordEmail = (event) => {
    let valid = true;
    let message = '';
    if (event.type !== "change") {
      if (event.target.value.length === 0) {
        valid = false;
        message = "Please enter a valid email address"
      }
      if (event.target.value.indexOf('@') === -1) {
        valid = false;
        message = "Please enter a valid email address"
      }
    }
    this.setState({
      forgotPasswordEmail: event.target.value,
      forgotPasswordEmailError: !valid,
      forgotPasswordEmailErrorMessage: message,
    })
  }

  submitForgotPassword = () => {
    if (this.state.forgotPasswordEmailError === false && this.state.forgotPasswordEmail !== '') {
      axios.post(settings.forgotPassword, {
        email: this.state.forgotPasswordEmail,
        action: 'forgotPassword',
      }).then(({ data }) => {
        if (data.success) {
          this.setState({
            alertOpen: true,
            alertMessage: data.message,
            alertType: 'success',
            alertTitle: 'New Password Sent',
          });
        } else {
          this.setState({
            alertOpen: true,
            alertMessage: data.message,
            alertType: 'error',
            alertTitle: 'Error',
          });
        }
      }).catch(function (error) {
        console.log(error);
      });
    } else {
      this.setState({
        alertOpen: true,
        alertMessage: 'Please enter a valid email address and try again.',
        alertTitle: 'Error',
        alertType: 'error',
      });
    }
  }

  renderManyAttemptsText = () => (
    <React.Fragment>
      Too many login attempts in a row. Please wait a few seconds before attempting to login again.
      After a few more failed login attempts, Maximus will lock your account for security reasons.
      If you&#39;re having trouble remembering your password, you should use
      <button
        onClick={this.showForgotPassword}
        className={this.props.classes.forgotPasswordButtonDialog}
      >
        Forgot Password
      </button>
    </React.Fragment>);

  renderForgotPassword = () => {
    const {
      showForgotPassword,
      forgotPasswordEmailError,
      forgotPasswordEmailErrorMessage,
      forgotPasswordEmail,
    } = this.state;

    return (
      <Dialog
        open={showForgotPassword}
        type={"confirm"}
        onRequestSave={this.handleDialogRequestCloseAccept}
        onRequestClose={this.handleDialogRequestCloseDeny}
        saveButtonText={"Submit"}
        title={"Forgot My Password"}
      >
        <DialogContent>
          <DialogContentText>
            Please enter the email address to your Maximus account below. <br/>
            A new password will be sent to the email address provided.
          </DialogContentText>
          <TextField
            name={'forgotPasswordEmail'}
            id={'forgotPasswordEmail'}
            type={'forgotPasswordEmail'}
            margin={'normal'}
            fullWidth
            onChange={this.validateForgotPasswordEmail}
            onBlur={this.validateForgotPasswordEmail}
            error={forgotPasswordEmailError}
            helperText={forgotPasswordEmailErrorMessage}
            value={forgotPasswordEmail}
            label="Email Address"
          />
        </DialogContent>
      </Dialog>
    )
  }

  renderErrorDialog = (alertOpen, alertTitle, alertMessage) =>
    <Dialog
      open={alertOpen}
      onRequestClose={this.handleAlertRequestClose}
      title={alertTitle}
      type={this.state.alertType}
    >
      {alertMessage}
    </Dialog>

  renderLoginButton = () =>
    <Grid item xs={12}>
      <SubmitButton
        handleSubmit={this.handleLogin}
        isLoading={this.state.submitting}
        content={'Login'}
        disabled={this.state.maxAttemptsReached}
      />
    </Grid>

  renderInput = (name, classes) =>
    <Grid item xs={12}>
      <TextField
        id={name}
        label={_.capitalize(name)}
        className={classes.textField}
        type={name}
        autoComplete={name}
        margin="normal"
        value={this.state[name]}
        onChange={({target: {value: password}}) => this.handleChange(name, password)}
      />
    </Grid>

  renderMaximusHelmet = () =>
    <Grid item xs={12}>
      <img alt="" src={maximusHelmet} className={this.props.classes.helmet}/>
    </Grid>

  renderMaximusLogo = () =>
    <Grid item xs={12}>
      <img alt="" src={maximuslogo} className={this.props.classes.logo}/>
    </Grid>

  renderForgotPasswordButton = () =>
    <div className={this.props.classes.forgotPasswordButotn}>
      <Button onClick={this.showForgotPassword} color="primary">
        Forgot Password?
      </Button>
      <br/>
      <Typography><a href="https://maximusx.com/sign-up/">Sign Up</a> &nbsp;&nbsp;|&nbsp;&nbsp; <a
        href="https://maximusx.com/terms-of-use/">Terms of Use</a> &nbsp;&nbsp;|&nbsp;&nbsp; <a
        href="https://maximusx.com/contact">Contact Us</a></Typography>
    </div>;

  renderLoginRight = (classes) =>
    <Grid item xs={12} md={6}>
      <Grid container justify={'center'} alignItems={'center'} className={classes.loginFormContainer}>
        {this.renderMaximusHelmet()}
        <form className={classes.loginForm}>
          {this.renderInput('email', classes)}
          {this.renderInput('password', classes)}
        </form>
        {this.renderLoginButton(classes)}
        {this.renderForgotPasswordButton()}
      </Grid>
    </Grid>

  renderLoginLeft = (classes) =>
    <Grid item xs={12} md={6} style={{height: '100%', textAlign: 'center'}}>
      <Grid container justify={'center'} alignItems={'center'} className={classes.loginLeftContainer}>
        <Grid item xs={12}>
          {this.renderMaximusLogo()}
          <p className={classes.tagLine}>Native Advertising Made Simple</p>
        </Grid>
      </Grid>
    </Grid>

  render() {
    const {classes} = this.props;
    const {alertOpen, alertMessage, alertTitle, loggedIn} = this.state;

    if (loggedIn) return (<Redirect to="/"/>);

    return (
      <Grid id={'loginPage'} container justify="center" alignItems="center">
        {this.renderLoginLeft(classes)}
        {this.renderLoginRight(classes)}
        {this.renderForgotPassword()}
        {this.renderErrorDialog(alertOpen, alertTitle, alertMessage)}
      </Grid>
    );
  }
}

Login.propTypes = propTypes;

export default withStyles(styles, 'sheet')(Login);
