export const colors = {
    50: '#f0f0f0',
    100: '#d9d9d9',
    200: '#c0c0c0',
    300: '#a6a6a6',
    400: '#939393',
    500: '#808080',
    600: '#787878',
    700: '#6d6d6d',
    800: '#636363',
    900: '#505050',
    A100: '#f8c0c0',
    A200: '#f39292',
    A400: '#ff5353',
    A700: '#000000',
    contrastDefaultColor: 'light',
    submit: '#4285f4',
    cancel: '#e5e5e5',
    darkCancel: '#939393',
    back: '#E0E0E0',
    next: '#4285f4',
    warning: '#FF5722',
    positiveProfit: 'green',
    negativeProfit: 'red',
    TDShadeGreen: 'rgb(111, 137,	50)',
    TDShadeRed: 'rgb(234, 28, 33)',
    TDShadeLightGray: 'rgba(0,0,0,0.10)',
    TDShadeWhite: 'rgb(255, 255, 255)',
    TDShadeOffWhite: 'rgba(0,0,0,0.05)',
    TDShadeGray: 'rgb(89, 97, 100)',
    TDShadeBlue: '#2c8fff',
};

export default colors;
