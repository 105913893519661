const DEV_SERVER = process.env.REACT_APP_MAXIMUS_DEV_SERVER || '';

const getRandomServer = () => {
  const dnsArray = [5, 6];
  return dnsArray[Math.floor(Math.random() * dnsArray.length)];
};

const getHostURL = (rr = false) => {
  if (DEV_SERVER) {
    return rr ? 'http://localhost:8082' : DEV_SERVER;
  }
  return rr ? 'https://shield.maximusnative.io' : `https://champion${getRandomServer()}.maximusnative.io`;
};

const getSettings = () => ({
  addCampaignURL: `${getHostURL()}/v1/addCampaign.php`,
  addCampaign: `${getHostURL(true)}/api/v1/campaign/add`,
  imageUpload: `${getHostURL()}/v1/imageUpload.php`,
  uploadImage: `${getHostURL(true)}/api/v1/campaign/upload/image`,
  uploadVideo: `${getHostURL(true)}/api/v1/campaign/upload/video`,
  dataURL: `${getHostURL()}/v1/media_buying.php`,
  sitesTable: `${getHostURL(true)}/api/v1/campaign/reporting/sites`,
  dataURLv2: `${getHostURL()}/v2/media_buying.php`,
  checkLoggedUser: `${getHostURL(true)}/api/v1/user/logged/check`,
  changeAdminUser: `${getHostURL(true)}/api/v1/user/change/admin`,
  getGroupsDropdown: `${getHostURL(true)}/api/v1/user/get-groups-dropdown`,
  updateBudget: `${getHostURL(true)}/api/v1/campaign/budget`,
  updateCPC: `${getHostURL(true)}/api/v1/campaign/cpc`,
  getCampaignNames: `${getHostURL(true)}/api/v1/campaign/copy/campaign`,
  getCampaignImages: `${getHostURL(true)}/api/v1/campaign/copy/images`,
  getRevContentLanguages: `${getHostURL(true)}/api/v1/campaign/language/3`,
  login: `${getHostURL(true)}/user/login`,
  checkToken: `${getHostURL(true)}/user/token/check`,
  getOutbrainAccounts: `${getHostURL(true)}/api/v1/user/accounts/2/list`,
  getUserNetworks: `${getHostURL(true)}/api/v1/user/get-user-networks`,
  dashboardURL: `${getHostURL(true)}/api/v1/dashboard/`,
  userVersionURL: `${getHostURL()}/v1/user_version.php`,
  getUUIDUrl: `${getHostURL()}/v1/getUUID.php`,
  links: `${getHostURL()}/links.php`,
  geminiAuth: `${getHostURL()}/gemini/auth.php`,
  forgotPassword: `${getHostURL()}/v1/forgot_password.php`,
  geminiUserReports: `${getHostURL()}/v2/gemini_user_reports.php`,
  notifications: `${getHostURL(true)}/api/v1/user/notifications`,
  notificationsMarkAsRead: `${getHostURL(true)}/api/v1/user/notifications_mark`,
  checkPrimaryUser: `${getHostURL(true)}/api/v1/user/logged/check/primary`,
  overrideAccounts: `${getHostURL(true)}/api/v1/user/accounts/override`,
  checkSetup: `${getHostURL()}/v2/users.php`,
  getPrimaryUsers: `${getHostURL(true)}/api/v1/user/get_primary_users`,
  geminiSiteDevice: `${getHostURL()}/v2/gemini_site_device.php`,
  getGeminiSitesDeviceList: `${getHostURL(true)}/api/v1/campaign/gemini/site/bids`,
  getAlerts: `${getHostURL(true)}/api/v1/user/alerts`,
  taboolaOrOutbrainSettings: `${getHostURL(true)}/api/v1/campaign/settings`,
  outbrainSegments: `${getHostURL()}/v2/outbrain_segments.php`,
  userSettings: `${getHostURL()}/v2/user_settings.php`,
  ffPreset: `${getHostURL()}/v2/ff_presets.php`,
  gemini: `${getHostURL()}/v2/gemini.php`,
  getGeminiLanguages: `${getHostURL(true)}/api/v1/campaign/language/5`,
  geminiDomain: `${getHostURL()}/v2/gemini_domains_report.php`,
  dashboard: `${getHostURL()}/v2/dashboard.php`,
  campaign: `${getHostURL()}/v2/campaign.php`,
  outbrain: `${getHostURL()}/v2/outbrain.php`,
  getOutbrainOS: `${getHostURL(true)}/api/v1/campaign/os/2`,
  whitelabel: `${getHostURL()}/v2/whitelabel.php`,
  tutorials: `${getHostURL(true)}/api/v1/user/tutorial-videos`,
  linkTest: `${getHostURL()}/v2/link_test.php`,
  linkTestStatus: `${getHostURL(true)}/api/v1/campaign/linkTest/status`,
  linkTestNotification: `${getHostURL(true)}/api/v1/campaign/linkTest/notification`,
  linkTestTotals: `${getHostURL(true)}/api/v1/campaign/linkTest/totals`,
  recommendations: `${getHostURL()}/v2/recommendations.php`,
  routing: `${getHostURL()}/v2/routing.php`,
  getImageURL: `${getHostURL()}/v2/getImage.php?url=`,
  geminiSettings: `${getHostURL(true)}/api/v1/campaign/gemini/settings`,
  geminiSettingsBid: `${getHostURL(true)}/api/v1/campaign/gemini/bid/modifiers`,
  geminiSettingsTargeting: `${getHostURL(true)}/api/v1/campaign/gemini/targeting`,
  geminiSettingsGroup: `${getHostURL(true)}/api/v1/campaign/gemini/group`,
  geminiSettingsCpc: `${getHostURL(true)}/api/v1/campaign/gemini/cpc`,
  topPerfomanceImages: `${getHostURL(true)}/api/v1/campaign/reporting/top-performing-images`,
  addImage: `${getHostURL(true)}/api/v1/campaign/add/image`,
  saveFFSettings: `${getHostURL(true)}/api/v1/campaign/update/ff`,
  getFFSettings: `${getHostURL(true)}/api/v1/campaign/get/ff`,
  getPresets: `${getHostURL(true)}/api/v1/campaign/get/presets`,
  statusActive: `${getHostURL(true)}/api/v1/campaign/update/activeStatus`,
  statusArchive: `${getHostURL(true)}/api/v1/campaign/update/archiveStatus`,
  budgetIncreaseMode: `${getHostURL(true)}/api/v1/campaign/update/budgetIncreaseMode`,
  statusFF: `${getHostURL(true)}/api/v1/campaign/update/ffStatus`,
  linksGet: `${getHostURL(true)}/api/v1/campaign/get/links`,
  linksUpdate: `${getHostURL(true)}/api/v1/campaign/update/links`,
  cloneCampaign: `${getHostURL(true)}/api/v1/campaign/clone`,
  uploadZipCode: `${getHostURL(true)}/api/v1/campaign/upload/file`,
  countZipCode: `${getHostURL(true)}/api/v1/campaign/get/zip/count`,
  userBidsCampaigns: `${getHostURL(true)}/api/v1/user/user-bids-campaigns`,
  glanceCampaign: `${getHostURL(true)}/api/v1/campaign/reporting/glance`,
  hourlyClickReport: `${getHostURL(true)}/api/v1/campaign/reporting/hourly-presell-clicks`,
  baidu: `${getHostURL()}/v2/baidu.php`,
  getUserSettings: `${getHostURL(true)}/api/v1/user/settings`,
  featureFlags: `${getHostURL(true)}/api/v1/user/feature-flags`,
  campaignData: `${getHostURL(true)}/api/v1/campaign/data`,
  getCheckSetup: `${getHostURL(true)}/api/v1/user/setup`,
  getOutbrainUserBlocks: `${getHostURL(true)}/api/v1/campaign/outbrain/max-blocks-count`,
  getOutbrainSiteBidsMax: `${getHostURL(true)}/api/v1/campaign/outbrain/max-site-bids-count`,
  userList: `${getHostURL(true)}/api/v1/user/list`,
  userData: `${getHostURL(true)}/api/v1/user/data`,
  getCpaGoal: `${getHostURL(true)}/api/v1/campaign/get/cpa-goal-reached`,
  logsReport: `${getHostURL(true)}/api/v1/campaign/reporting/logs`,
  presellSites: `${getHostURL(true)}/api/v1/campaign/reporting/presell-site`,
  manageTotal: `${getHostURL(true)}/api/v1/campaign/manage/totals`,
  manageCampaigns: `${getHostURL(true)}/api/v1/campaign/manage/campaigns`,
  geminiCpcModifier: `${getHostURL(true)}/api/v1/campaign/gemini/cpc/modifiers`,
  imagesReport: `${getHostURL(true)}/api/v1/campaign/reporting/images`,
  chartClicks: `${getHostURL(true)}/api/v1/campaign/reporting/chart/clicks`,
  allImages: `${getHostURL(true)}/api/v1/campaign/reporting/images/all`,
  conversionsReport: `${getHostURL(true)}/api/v1/campaign/reporting/conversions`,
  clicksReport: `${getHostURL(true)}/api/v1/campaign/reporting/clicks`,
  clicksOfferReport: `${getHostURL(true)}/api/v1/campaign/reporting/offer/clicks`,
  //clicksReport: `${getHostURL()}/v1/media_buying.php`,
  presellReport: `${getHostURL(true)}/api/v1/campaign/reporting/presell`,
  platformReport: `${getHostURL(true)}/api/v1/campaign/reporting/platform`,
  stateReport: `${getHostURL(true)}/api/v1/campaign/reporting/state`,
  approvedState: `${getHostURL(true)}/api/v1/campaign/approval`,
  rules: `${getHostURL(true)}/api/v1/campaign/rules`,
  ffMessage: `${getHostURL(true)}/api/v1/campaign/get/ff/message`,
  activeItemsCount: `${getHostURL(true)}/api/v1/campaign/get/items/active`,
  rulesInactive: `${getHostURL(true)}/api/v1/campaign/get/rules/inactive`,
  offerLinks: `${getHostURL(true)}/api/v1/campaign/get/offer/links`,
  archiveStatus: `${getHostURL(true)}/api/v1/campaign/get/archive`,
});

export const getDataURL = () => getSettings().dataURL;

export const settings = getSettings();
