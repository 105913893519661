import React  from 'react';
import {Redirect} from 'react-router-dom';

class Logout extends React.Component {
    constructor(props) {
        super(props);
        // console.log("+++++++++");
        // console.log(props.getAuthData);
        // console.log("+++++++++");
        this.processLogOut = this.processLogOut.bind(this);
    }

    processLogOut() {
        // console.log('processing logout');
        if(localStorage.getItem('token')) {
            localStorage.removeItem('token');
        }
        if(localStorage.getItem('aup')) {
            localStorage.removeItem('aup');
        }
      if (localStorage.getItem('tutorialVideosShowed')) {
        localStorage.removeItem('tutorialVideosShowed');
      }
        this.props.getAuthData();
    }

    componentWillMount() {

    }
    componentDidMount() {
        window.location.reload();
    }

    render() {
        this.processLogOut();
        return(<Redirect to="/login"/>)
    }
}
export default Logout;
