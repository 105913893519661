import { GROUPS_GET_LIST, GROUPS_SET_MESSAGE, GROUPS_SET_PROCESSING } from '../actions/groups';

const initialState = {
    list: [],
    processing: false,
    message: {
        status: true,
        message: ''
    }
};

export const groups = (state = initialState, action) => {
    switch (action.type) {
        case GROUPS_SET_PROCESSING:
            return {
                ...state,
                processing: action.processing
            };
        case GROUPS_SET_MESSAGE:
            return {
                ...state,
                message: action.message
            };
        case GROUPS_GET_LIST:
            return {
                ...state,
                list: action.groups
            };
        default:
            return state;
    }
};
