import {initialState} from "../reducers/adSetup";

const setStateVal = (value, error = false, message = '') => {
  return {error, message, value};
};

export const handleChange = (state, value, valid = true, message = '') => {
  return {
    state,
    type: "HANDLE_AD_CHANGE",
    value: setStateVal(value, !valid, message),
  };
};

export const handleChangeNonObject = (state, value) => {
  return {
    state,
    type: "HANDLE_AD_CHANGE",
    value,
  };
};

export const removeFile = (adType, key, carouselID = false) => {
  if(adType === 'carouselAds') {
    return removeCarouselFile(adType, key, carouselID)
  }
  return {
    key,
    adType,
    type: "REMOVE_AD",
  };
};

export const removeCarouselFile = (adType, key, carouselID) => {
  return {
    key,
    adType,
    carouselID,
    type: "REMOVE_CAROUSEL_AD",
  };
};

export const addFile = (adType, value, valid = true, message = '', carouselID = false, title = '', description = '', squareFile = '') => {
  if(adType === 'carouselAds') {
    return addCarouselFile(adType, value, valid, message, carouselID)
  }
  return {
    type: "ADD_AD",
    adType,
    value: value,
    error: !valid,
    message: message,
    title,
    description,
    squareFile,
  };
};

export const addCarouselFile = (adType, value, valid = true, message = '', carouselID) => {
  return {
    type: "UPLOAD_CAROUSEL_AD",
    adType,
    carouselID,
    value: value,
    error: !valid,
    message: message,
  };
};

export const handleAdChange = (adType, key, stateName, value, error = true, message = '', carouselID = false) => {
  if(adType === 'carouselAds') {
    return handleCarouselAdChange(adType, key, stateName, value, error, message, carouselID)
  }
  return {
    type: "CHANGE_AD",
    adType,
    key,
    stateName,
    value: setStateVal(value, error, message),
  };
};

export const handleCarouselAdChange = (adType, key, stateName, value, error = true, message = '', carouselID) => {
  return {
    type: "CAROUSEL_CHANGE_AD",
    adType,
    key,
    stateName,
    carouselID,
    value: setStateVal(value, error, message),
  };
};

export const handleCarouselNumberChange = ({target: {value}}) => {
  return {
    type: "CHANGE_CAROUSEL_NUMBER",
    value: setStateVal(value),
  };
};

export const toggleImageError = (bool = false, url = '', errorMessage = '') => {
  return {
    type: "IMAGE_ERROR",
    value: {
      status: bool,
      url,
      errorMessage,
    },
  };
};

export const toggleVideoUpload = () => {
  return {
    type: "TOGGLE_VIDEO_UPLOAD",
  };
};

export const addClonedAd = value => {
  return {
    type: "ADD_CLONED_AD",
    value,
  };
};

export const addClonedCarouselAd = (value, carouselID) => {
  return {
    type: "ADD_CLONED_CAROUSEL_AD",
    carouselID,
    value,
  };
};

export const resetAdSetup = () => {
  const newState = {...initialState};
  return {
    type: "RESET_AD_SETUP",
    newState
  };
};

export const loadAdSetup = (fields) => {
  const newState = {...initialState};

  Object.keys(fields).map(fieldName => {
    if(newState[fieldName] !== undefined && fields[fieldName] !== undefined ) {
      newState[fieldName] = setStateVal(fields[fieldName]);
    }
    return true;
  })

  return {
    type: 'LOAD_AD_SETUP',
    newState
  }
};

export const loadUserSettings = ({
  carousel = true,
  callToAction = true,
  translate_button: translateButton = true,
  revcontent_alert: revcontentAlert = false,
  notifications = true,
  uploadCrop = false,
  gemini_high_cpc_alert: geminiHighCPCAlert = true,
  tutorial_videos: tutorialVideos = true,
}) => ({
  type: 'LOAD_USER_SETTINGS',
  value: {
    carousel: !!+carousel,
    callToAction: !!+callToAction,
    translateButton: !!+translateButton,
    revcontentAlert: !!+revcontentAlert,
    notifications: !!+notifications,
    uploadCrop: !!+uploadCrop,
    geminiHighCPCAlert: !!+geminiHighCPCAlert,
    tutorialVideos: !!+tutorialVideos,
  },
});

export const setUserSetting = (name, value) => ({
  type: 'SET_USER_SETTING',
  name,
  value,
});

export const resetCarouselAds = () => ({ type: 'RESET_CAROUSEL_ADS' });
