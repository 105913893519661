const pausedStatusStrings = ['pending_approval' , 'rejected', 'disabled' , 'paused', '' , 'inactive'];

export const formatCampaignStatus = (status) => {
  if (typeof status === 'string') {
    if (pausedStatusStrings.includes(status.toLowerCase())) {
      return 0
    } else {
      return 1;
    }
  }
  return status;
}

