import axios from 'axios';
import { each } from 'lodash';

export default class NetworkRequest {
  static addToken(paramsOrig = {}) {
    return Object.assign({}, paramsOrig, {
      token: localStorage.getItem('token')
    })
  }

  static async send(url, paramsOrig, callbackFunction) {
    const params = this.addToken(paramsOrig);

    axios.post(url, {
      params,
    }).then((response) => {
      callbackFunction(response);
      if (params.action === 'campaign_image_details') {
        response.data.forEach((value) => {
          localStorage.removeItem(value.item_id);
        });
      } else if (params.action === 'campaign_list') {
        response.data.campaigns.forEach((value) => {
          localStorage.removeItem(value.campaign_id);
        });
      }
    }).catch(() => false);
  }

  static post(url, paramsOrig, options) {
    const params = {
      ...paramsOrig,
      ...this.addToken(),
    };
    if (params.token) {
      return axios.post(url, {
        params,
      }, options);
    }
    return Promise.reject(new Error('You are not logged in'));
  }

  static put(url, paramsOrig, options) {
    const params = {
      ...paramsOrig,
      ...this.addToken(),
    };
    if (params.token) {
      return axios.put(url, {
        params,
      }, options);
    }
    return Promise.reject(new Error('You are not logged in'));
  }

  static postFormData(url, data, options = {}) {
    const token = localStorage.getItem('token');
    if (token) {
      const formData = new FormData();
      each(data, (paramOrig, key) => {
        const param = paramOrig;
        if (key === 'params') {
          param.token = token;
          formData.append(key, JSON.stringify(param));
        } else { formData.append(key, param); }
      });
      return axios.post(url, formData, options);
    }
    return Promise.reject(new Error('You are not logged in'));
  }

  static sendAll(data) {
    // loop through data, expect an array of url and params
    // build axios post objects per array
    // run the spread, send back responses
    // https://stackoverflow.com/questions/37213783/waiting-for-all-promises-called-in-a-loop-to-finish
    const requests = data.map(({ url, params, options = {} }) => axios.post(url, params, options));
    return Promise.all(requests);
  }
}

export const getToken = () => localStorage.getItem('token');

const defaultTransformData = (data) => ({
  params: {
    ...data,
    token: getToken(),
  },
});

const request = ({
  transformData = defaultTransformData,
  ...config
}) =>
  axios
    .request({
      ...config,
      data: transformData(config.data),
    })
    .then((response) => response.data);

export const post = (url, data, config) => request({
  method: 'post',
  url,
  data,
  config,
});
