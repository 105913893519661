import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { differenceInMinutes } from 'date-fns';
import { IconButton, Tooltip } from '@material-ui/core';
import { Replay as ReplayIcon } from '@material-ui/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { refreshData, updateRefreshedAt } from '../../actions/navigation';

class LastUpdated extends Component {
  state = {
    lastUpdatedText: 'Now',
    updatedTime: new Date(),
  };

  componentDidMount() {
    this.checkUpdatedTime();
    this.intervalID = setInterval(this.checkUpdatedTime, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  intervalID = null;

  checkUpdatedTime = () => {
    const { updatedAt, table } = this.props;
    const diff = differenceInMinutes(
      new Date(),
      table ? this.state.updatedTime : updatedAt,
    );
    let text = '';
    if (diff < 1 || !diff) {
      text = 'Now';
    } else if (diff < 5) {
      text = 'Less than 5 Minutes Ago';
    } else {
      text = `${diff} Minutes Ago`;
    }
    this.setState({ lastUpdatedText: text });
  };

  render() {
    const {
      onReload, tableUpdatedAt, table,
    } = this.props;
    return (
      <div
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          paddingRight: table ? 17 : 0,
          color: table ? 'inherit' : 'white',
        }}
      >
        <Tooltip title={table ? '' : `Last Updated: ${this.state.lastUpdatedText}`}>
          <IconButton
            onClick={() => {
              this.setState({ updatedTime: new Date() }, this.checkUpdatedTime);
              if (table) {
                onReload();
              } else {
                this.props.refreshData();
                this.props.updateRefreshedAt();
              }
            }}
            variant="contained"
          >
            <ReplayIcon style={{ fill: table ? 'inherit' : 'white' }} />
          </IconButton>
        </Tooltip>
        {table && `Last Updated: ${this.state.lastUpdatedText} ${tableUpdatedAt && `(Latest Data: ${tableUpdatedAt})`}`}
      </div>);
  }
}

LastUpdated.propTypes = {
  updatedAt: PropTypes.instanceOf(Date).isRequired,
  updateRefreshedAt: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  tableUpdatedAt: PropTypes.string,
  table: PropTypes.bool,
  onReload: PropTypes.func,
};

LastUpdated.defaultProps = {
  tableUpdatedAt: '',
  table: false,
  onReload: () => {},
};

export default connect(state => ({
  updatedAt: state.navigation.updatedAt,
}), dispatch =>
  bindActionCreators({
    refreshData,
    updateRefreshedAt,
  }, dispatch))(LastUpdated);
