import axios from 'axios';
import {settings} from "../Settings";

const boolMapping = {
  3301120 : false,
  928312 : true,
};

export const getFeatureFlags = () => {
  axios.post(settings.featureFlags,
    {
      params: {
        token: localStorage.getItem('token')
      }
    }
  ).then(({data}) => {
    if(data) {
      localStorage.setItem("aup", JSON.stringify(data))
    }
  }).catch(function (error) { });
};

export const checkPrimaryUser = () => {
  axios.post(settings.checkPrimaryUser,
    {
      params: {
        token: localStorage.getItem('token')
      }
    }
  ).then(({data}) => {
    if(typeof data.status === 'boolean') {
      localStorage.setItem("puc", JSON.stringify(data.status))
    }
  }).catch(function (error) { });
};

const getJSONValueBool = (name, defaultVal) => {
  if(localStorage.getItem('aup')) {
    const parsedValue = JSON.parse(localStorage.getItem('aup'));
    return !!parsedValue ? boolMapping[parsedValue[name]] : defaultVal;
  }
  return defaultVal;
};

const getJSONValue = (name, defaultVal) => {
  if(localStorage.getItem('aup')) {
    const parsedValue = JSON.parse(localStorage.getItem('aup'));
    return parsedValue ? parsedValue[name] : defaultVal;
  }
  return defaultVal;
};

export const featureFlagged = () => getJSONValueBool('gup', false);

export const userIsUSM = () => getJSONValueBool('ualb', false);

export const checkMaximusLite = () => getJSONValueBool('uvs', false);

export const dupCampaignsAllowedUser = () => getJSONValueBool('dcu', false);

export const adminUser = () => getJSONValueBool('auf', false);

export const isPrimaryUser = () => localStorage.getItem('puc') ? localStorage.getItem('puc') === 'true' || featureFlagged() : false;

export const currency = () => getJSONValue('uc', 'dollar');

export const timezone = () => getJSONValue('utz', 'America/New_York');

export const currencyMultiplier = () => getJSONValue('ucv', 1);
