import React from 'react';
import App from './App';
import {Provider} from 'react-redux';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import 'typeface-roboto'
import colors from './components/MaximusColor';
import reduxStore from './reduxStore';
import './index.css';

const theme = createMuiTheme({
  background: {
    "default": "#fafafa",
    "paper": "#fff",
    "appBar": "#000000",
    "contentFrame": "#eeeeee"
  },
  overrides: {
    MuiTableBody: {
      root: {
        fontSize: '16px'
      }

    },
    MuiAppBar: {
      root: {
        backgroundColor: '#FFFFFF',
        borderRadius: 1,
        border: 0,
        color: 'black',
        padding: '0px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .30)',
      },
      colorPrimary: {
        color: 'black',
      }
    },
    MuiFormControl: {
      root: {
        focused: '#000000'
      }
    },
    MuiButton: {
      root: {
        backgroundColor: '#FFFFF',
        margin: '2px',
        color: '#000000',
      },

    },
  },
  button: {
    primary: '#808080',
    secondary: '',
  },
  paper: {
    backgroundColor: '#000000',
  },
  palette: {
    primary: colors,
    secondary: {
      light: "#72a5f4",
      main: "#4285f4",
      dark: "#2d6ede",
      contrastText: "#fff",
    },
  },
  typography: {
    useNextVariants: true,
  },
});

function Root() {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={reduxStore}>
        <App/>
      </Provider>
    </MuiThemeProvider>
  );
}

ReactDOM.render(<Root/>, document.getElementById('root'));
