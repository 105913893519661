import axios from 'axios';
import {settings} from "../../../Settings";
import NetworkRequest from "../../../utilities/NetworkRequest";

export default class Requests {
  static getCopyImages = async (campaign_id, network) => {
    const {
      data: {
        data: images = []
      } = {}
    } = await axios.post(settings.getCampaignImages, {
      params: {
        token: localStorage.getItem('token'),
        campaign_id,
        network
      }
    });
    return images || [];
  };

  static getAllCloneImages = async (sourceCampaignName) => {
    const {
      data = []
    } = await axios.post(settings.allImages, {
      params: {
        token: localStorage.getItem('token'),
        campaign_name: sourceCampaignName
      }
    });
    return data;
  };

  static fetchRevContentLanguages = async () => {
    const {
      data = []
    } = await axios.post(settings.getRevContentLanguages, {
      params: {
        token: localStorage.getItem('token'),
      }
    });
    return data
  };

  static getCampaignsDropdown = async (networks) => {
    const {
      data = []
    } = await axios.post(settings.getCampaignNames, {
      params: {
        token: localStorage.getItem('token'),
        networks,
      }
    });
    return data;
  }

  static loadUserSettings = async () => {
    const params = { action: 'get_user_setting' };
    const { data } = await NetworkRequest.post(settings.getUserSettings, params);
    return data;
  };
}