export const initialState = {
  clone: false,
  existingCampaignName: '',
  campaignType: 'native',
  steps: [],
  submitting: false,
  amountOfDuplicates: 1,
  userSetCustomBid: false,
};

export const addCampaign = (state = initialState, action) => {
  switch (action.type) {
    case 'IS_CLONE':
      return {
        ...state,
        clone: true,
        existingCampaignName: action.name,
      };
    case 'HANDLE_ADD_CAMPAIGN_CHANGE':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'RESET_ADD_CAMPAIGN':
      return { ...initialState };
    default:
      return state;
  }
};
