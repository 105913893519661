import React, {Component} from 'react';
import Dialog from "../common/dialog/Dialog";
import maximuslogo from '../../assets/images/maximus-logo.png';
import NetworkRequest from '../../utilities/NetworkRequest';
import {settings} from "../../Settings";

const styles = {
  errorMessage: {
    fontSize: '1.2rem',
    padding: '0 2rem',
    marginTop: "-1rem",
  }
}

const isJSON = string => {
  try{
    JSON.parse(string);
  } catch {
    return false
  }
  return true;
}

export default class AlertContainer extends Component {
  state = {
    open: false,
    message: {message: '', link: '', id: 0},
    acceptedIDs: []
  }

  async componentDidMount() {
    if(localStorage.getItem('token')) {
      await this.getAcceptedIDs();
      const { data } = await this.getMaximusAlert();
      if (data && data.message && data.id && !this.state.acceptedIDs.includes(data.id)) {
        this.setState({
          message: {
            message: data.message,
            link: data.link,
            id: data.id,
          },
          open: true,
        });
      }
    }
  }

  getAcceptedIDs = async () => {
    const ids = localStorage.getItem('acceptedAlerts');
    if(ids && isJSON(ids)) {
      const acceptedIDs = JSON.parse(ids);
      this.setState({acceptedIDs});
    }
  }

  updateAcceptedIDs = (id) => {
    const {acceptedIDs} = this.state;
    acceptedIDs.push(id);
    localStorage.setItem('acceptedAlerts', JSON.stringify(acceptedIDs));
  }

  getMaximusAlert = async () => {
    return await NetworkRequest.post(settings.getAlerts, {token: localStorage.getItem('token'), action: 'get_maximus_alerts'})
  }

  renderMaximusLogo = () =>
    <div>
      <img src={maximuslogo} alt=""/>
    </div>

  renderMessage = (message) =>
    <p style={styles.errorMessage}>
      {message.message}
    </p>

  renderMessageLink = (message) =>
    <a target="_blank" rel="noopener noreferrer" href={message.link}>
      {message.link}
    </a>

  closeAlert = (id) => this.setState({open: false}, () => this.updateAcceptedIDs(id));

  render() {
    const {open, message} = this.state;
    return (
      <Dialog
        open={open}
        title={this.renderMaximusLogo()}
        type={'error'}
        onRequestClose={() => this.closeAlert(message.id)}
      >
        {this.renderMessage(message)}
        {message.link && this.renderMessageLink(message)}
      </Dialog>
    );
  }
}
