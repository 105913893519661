import { isNaN } from 'lodash';

import { post } from '../utilities/NetworkRequest';
import NetworkRequest from '../utilities/NetworkRequest';
import { settings } from '../Settings';
import { geminiGroupToTitleMap } from '../utilities/networks';
import Requests from '../components/AdSetup/utilities/adSetupRequests';

const formatGeminiCpcGroup = (value) => {
  const floatValue = parseFloat(value.substring(1));

  if (isNaN(floatValue)) {
    return value;
  }

  return `${value[0]}${floatValue.toFixed(2)}`;
};

export const getGeminiCpcGroups = (campaign_name) => {
  const params = {
    action: 'get_gemini_bid_modifier',
    campaign_name,
  };

  return post(settings.geminiCpcModifier, params).then((geminiCpcGroups) => {
    if (!geminiCpcGroups.data) {
      return [];
    }

    return Object
      .keys(geminiCpcGroups.data)
      .sort()
      .map((key) => ({
        name: geminiGroupToTitleMap[key],
        cpc: formatGeminiCpcGroup(geminiCpcGroups.data[key]),
      }));
  });
};

export const archiveCampaign = ({ isActive, campaignId, network }) => {
  return NetworkRequest.put(settings.statusArchive, {
    action: 'archive_campaign',
    archive_status: isActive ? 1: 0,
    campaign_id: campaignId,
    network,
  });
};

export const getUserSettings = async () => {
  return Requests.loadUserSettings();
};
