import React from 'react'
import {
    BrowserRouter as Router,
    Route,
    //Link,
    // Redirect,
    //withRouter
} from 'react-router-dom';

import Login from './Login';
import Logout from './Logout';

const MaximusPublicRoute = ({component, ...props}) => (
    <Router>
        <div>
            {/*<Route path="/signup" component={Signup}/>*/}
            <Route exact path="/" render={()=><Login getAuthData={props.getAuthData}/>}/>
            <Route exact path="/login" render={()=><Login getAuthData={props.getAuthData}/>}/>
            <Route exact path="/logout" render={()=><Logout getAuthData={props.getAuthData}/>}/>
        </div>
    </Router>
);
export default MaximusPublicRoute;