import { HANDLE_GEMINI_CHANGE } from '../actions/geminiSettings';

const setStateVal = (value, error = false, message = '') => {
  return {error, message, value};
};

export const initialState = {
  geminiAges: setStateVal([]),
  geminiGenders: setStateVal([]),
  geminiCpc: setStateVal(0.05),
  geminiFFGroup: setStateVal(false),
  geminiGroup1a: setStateVal(800),
  geminiGroup1b: setStateVal(-40),
  geminiGroup2a: setStateVal(-30),
  geminiGroup2b: setStateVal(-70),
  geminiGroup3a: setStateVal(-50),
  geminiGroup3b: setStateVal(-80),
};

export const geminiSettings = (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_GEMINI_CHANGE:
      return {
        ...state,
        [action.state]: action.value
      };
    case 'SET_GEMINI_FF_GROUP':
      return {
        ...state,
        geminiFFGroup: setStateVal(action.value)
      };
    case 'RESET_GEMINI_SETTINGS':
      return action.newState;
    case 'LOAD_GEMINI_VALUES':
      const {newState} = action;
      return newState;
    default:
      return state;
  }
};