import { UPDATE_DATAGRID } from '../actions/table';

const initialState = {
  datagrid_customization: {
    columnOrder: {},
    columnSizes: {},
    hideColumns: {},
  },
};

export const table = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DATAGRID:
      return {
        ...state,
        datagrid_customization: action.value,
      };
    default:
      return state;
  }
};
