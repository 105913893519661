import React from 'react';
import { ListItem, ListItemText, ListItemAvatar, Tooltip } from '@material-ui/core';
import logo from '../../assets/images/Maximus_Icon1.png';

export default function NotificationsList(props) {
  return (
    props.list.length ?
      props.list.map((item, index) => (
        <ListItem
          button
          key={item.id}
          style={{
            paddingBottom: index === props.list.length - 1 ? '4rem' : 'inherit',
            alignItems: 'flex-start',
          }}
        >
          <ListItemAvatar style={{ height: 30, marginTop: 5 }}>
            <img src={logo} alt="" />
          </ListItemAvatar>
          <ListItemText
            primary={
              <a
                style={{ textDecoration: 'none', color: 'black' }}
                title={item.campaign_name}
                href={`/campaign/${item.campaign_name}`}
              >
                {item.campaign_name}
              </a>
            }
            secondary={
              <React.Fragment>
                <p style={{ marginTop: 2, marginBottom: 5 }}>
                  {item.message}
                </p>
                <p style={{ color: 'rgba(0,0,0,0.35)', margin: 0 }}>
                  {item.created_at}
                </p>
              </React.Fragment>
            }
            classes={{ primary: item.read ? '' : props.primaryClass }}
          />
          {!item.read &&
            <Tooltip title="Click to mark this message as read">
              <span
                onClick={() => props.handleClick(item)}
                style={{
                  width: 12, height: 12, borderRadius: '50%', backgroundColor: 'dodgerblue', marginTop: 10,
                }}
              />
            </Tooltip>}
        </ListItem>)) :
      <ListItem>
        <ListItemText primary="No notifications" />
      </ListItem>
  );
}
