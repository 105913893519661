import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Typography,
  Grid,
  DialogTitle,
  DialogContent,
  Dialog,
  Button,
} from '@material-ui/core';
import AdminUsersDropdown from './AdminUsersDropdown';
import NetworkRequest from '../utilities/NetworkRequest';
import { settings } from '../Settings';

const styles = () => ({
  paper: {
    width: 500,
  },
});

class AdminSettingsComponent extends Component {
  state = {
    selectedUser: 'all_users',
  };

  componentDidMount() {
    if (typeof this.props.onRef === 'function') {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    if (typeof this.props.onRef === 'function') {
      this.props.onRef(undefined);
    }
  }

  handleUsersDropdownChange = e =>
    this.setState({ selectedUser: e.target.value });

  handleUserChange = () => {
    this.handleNetworkRequest(this.state.selectedUser);
    localStorage.setItem('user_filter', 'all_users');
  };

  handleCancelUser = (e) => {
    localStorage.setItem('user_filter', 'all_users');
    this.handleNetworkRequest(false);
    e.preventDefault();
  };

  handleNetworkRequest = (userID) => {
    const callBack = ({ data }) => {
      localStorage.setItem('token', JSON.stringify(data));
      this.props.checkLoggedInStatus(true);
    };
    const params = { userId: userID };
    NetworkRequest.send(settings.changeAdminUser, params, callBack);
  };

  render() {
    const {
      closeDialog, classes, open, userWarning,
    } = this.props;
    return (
      <Dialog open={open} onClose={closeDialog} classes={{ paper: classes.paper }}>
        <DialogTitle style={{ textAlign: 'center' }}>Admin</DialogTitle>
        <DialogContent>
          <Grid container align="center" justify="center">
            <Grid item xs={12} style={{ display: 'flex', padding: '1rem', borderBottom: 'solid 1px rgba(0,0,0,0.1)' }}>
              {userWarning ?
                <Typography>
                  {userWarning} <a href=" " onClick={this.handleCancelUser}>Stop</a>
                </Typography> :
                <Grid container>
                  <AdminUsersDropdown
                    onUsersChange={this.handleUsersDropdownChange}
                    value={this.state.selectedUser}
                  />
                  <Button variant="contained" onClick={this.handleUserChange}>Change</Button>
                </Grid>}
            </Grid>
            <Grid item xs={12} style={{ padding: '30px 0', textAlign: 'left' }}>
              <div style={{ padding: '10px' }}>
                Click here to view Admin Reporting
              </div>
              <Button
                href="/admin_reporting"
                variant="contained"
                onClick={closeDialog}
              >
                Admin Reporting
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

AdminSettingsComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  userWarning: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  classes: PropTypes.instanceOf(Object).isRequired,
  checkLoggedInStatus: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onRef: PropTypes.func.isRequired,
};

export default withStyles(styles)(AdminSettingsComponent);
