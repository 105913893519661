import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { groupBy, sortBy } from 'lodash';
import { TextField } from '@material-ui/core';
import { settings } from '../Settings';
import NetworkRequest from '../utilities/NetworkRequest';

export default class AdminUsersDropDown extends Component {
  state = {
    users: [],
  };

  componentDidMount() {
    this.getUsers();
  }

  getUsers = async () => {
    const { data } = await NetworkRequest.post(settings.userList, {});
    this.setState({ users: data.data });
  };

  renderSelectItems = () => {
    const { users } = this.state;
    const items = [<option key="all users" value="all_users">All Users</option>];
    const groupedUsers = groupBy(users, 'company');
    let companies = Object.keys(groupedUsers).sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    companies.forEach(company =>
      items.push(<optgroup label={company}>
        {sortBy(groupedUsers[company], 'user_fullname').map(user =>
          <option key={user.id} value={user.id}>{user.user_fullname}</option>)}
        </optgroup>));
    return items;
  };

  render() {
    return (
      <TextField
        id="select-native"
        className="select-native"
        select
        label="Change User"
        value={this.props.value}
        fullWidth
        onChange={this.props.onUsersChange}
        SelectProps={{ native: true }}
        margin="normal"
      >
        {this.renderSelectItems()}
      </TextField>
    );
  }
}

AdminUsersDropDown.propTypes = {
  onUsersChange: PropTypes.func.isRequired,
};
