import co from 'co';
import { settings } from '../Settings';
import axios from 'axios';
import { map } from 'lodash';
import NetworkRequest from '../utilities/NetworkRequest';

export const USERS_GET_LIST = 'USERS_GET_LIST';
export const USERS_SET_MESSAGE = 'USERS_SET_MESSAGE';
export const USERS_SET_PROCESSING = 'USERS_SET_PROCESSING';
export const USERS_GET_MASTER_USERS = 'USERS_GET_MASTER_USERS';

export const setUsersProcessing = processing => ({
  type: USERS_SET_PROCESSING,
  processing,
});

export const getUsersList = () => dispatch => co(function* () {
  dispatch(setUsersProcessing(true));

  const { data } = yield axios.post(settings.userList, {
    params: {
      token: localStorage.getItem('token'),
      key: 'id',
      value: 'user_fullname',
    },
  });

  dispatch({
    type: USERS_GET_LIST,
    users: map(data.data, (name, u_id) => ({ u_id, name })),
  });
  dispatch(setUsersProcessing(false));
}).catch((error) => {
  dispatch(usersSetMessage(error));
  dispatch(setUsersProcessing(false));
});

export const getMasterUsersList = () => async (dispatch) => {
  dispatch(setUsersProcessing(true));

  try {
    const { data = {} } = await NetworkRequest
      .post(settings.getPrimaryUsers, { action: 'get_master_users' });
    dispatch({
      type: USERS_GET_MASTER_USERS,
      users: map(data, ({ name, user_id: userID }) => ({ userID, name })),
    });
    dispatch(setUsersProcessing(false));
  } catch (e) {
    dispatch(setUsersProcessing(false));
  }
};

export const addUserToGroup = ({ u_id }, { g_id }) => dispatch => co(function* () {
  dispatch(setUsersProcessing(true));

  const { data: { status, message } } = yield axios.post(settings.dataURL, {
    params: {
      action: 'add_existing_user_to_group',
      token: localStorage.getItem('token'),
      u_id,
      g_id,
    },
  });

  dispatch(usersSetMessage({ status, message }));
  dispatch(setUsersProcessing(false));
}).catch((error) => {
  dispatch(usersSetMessage(error));
  dispatch(setUsersProcessing(false));
});

export const removeUserFromGroup = ({ u_id: userId }, { g_id: groupId }) => dispatch => co(function* () {
  dispatch(setUsersProcessing(true));

  const { data: { status, message } } = yield axios.post(settings.dataURL, {
    params: {
      action: 'remove_user_from_group',
      token: localStorage.getItem('token'),
      userId,
      groupId,
    },
  });

  dispatch(usersSetMessage({ status, message }));
  dispatch(setUsersProcessing(false));
}).catch((error) => {
  dispatch(usersSetMessage(error));
  dispatch(setUsersProcessing(false));
});

export const usersSetMessage = (message) => {
  if (message instanceof Error) {
    return {
      type: USERS_SET_MESSAGE,
      message: {
        status: false,
        message: message.toString(),
      },
    };
  }
  return {
    type: USERS_SET_MESSAGE,
    message,
  };
};
