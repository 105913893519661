export const initialState = {
  list: [],
  loading: true,
};

export const notifications = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NOTIFICATIONS':
      return {
        ...state,
        list: action.notifications,
        loading: action.loading,
      };
    case 'MARK_NOTIFICATION_AS_READ':
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === action.id) {
            return {
              ...item,
              read: 1,
            };
          }
          return item;
        }),
      };
    default:
      return state;
  }
};
