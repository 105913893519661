import axios from 'axios';
import {settings} from "../Settings";

export const redirectNewUser = (location) => {
  axios.post(settings.getCheckSetup, {params: { action: 'check_setup', token: localStorage.getItem('token')}})
    .then(({data: {accounts_check = true, video_check = true}}) => {
      if(((!accounts_check && !video_check) || !video_check) && location !== '/welcome' && location !== '/dashboard') {
        window.location.assign('/welcome');
      } else if ((accounts_check && video_check) && location === '/welcome') {
        window.location.assign('/dashboard');
      }
    })
}