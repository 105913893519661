import co from 'co';
import { settings } from '../Settings';
import axios from 'axios';

export const GROUPS_GET_LIST = 'GROUPS_GET_LIST';
export const GROUPS_SET_MESSAGE = 'GROUPS_SET_MESSAGE';
export const GROUPS_SET_PROCESSING = 'GROUPS_SET_PROCESSING';

export const setGroupsProcessing = (processing) => ({
    type: GROUPS_SET_PROCESSING,
    processing
});

export const createGroup = (groupName) => (dispatch) => co(function * () {
    dispatch(setGroupsProcessing(true));

    const { data: { status, message } } = yield axios.post(settings.dataURL, {
        params: {
            action: 'create_group',
            token: localStorage.getItem('token'),
            groupName
        }
    });

    dispatch(groupsSetMessage({ status, message }));
    if (status) {
        dispatch(getGroupsList());
    } else {
        dispatch(setGroupsProcessing(false));
    }
}).catch((error) =>
    dispatch(groupsSetMessage(error))
);

export const updateGroup = ({ g_id, name }) => (dispatch) => co(function * () {
    dispatch(setGroupsProcessing(true));

    const { data: { status, message } } = yield axios.post(settings.dataURL, {
        params: {
            action: 'update_group',
            token: localStorage.getItem('token'),
            groupName: name,
            groupId: g_id
        }
    });

    dispatch(groupsSetMessage({ status, message }));
    if (status) {
        dispatch(getGroupsList());
    } else {
        dispatch(setGroupsProcessing(false));
    }
}).catch((error) =>
    dispatch(groupsSetMessage(error))
);

export const getGroupsList = () => (dispatch) => co(function * () {
    dispatch(setGroupsProcessing(true));

    const { data: groups = [] } = yield axios.post(settings.getGroupsDropdown, {
        params: {
            token: localStorage.getItem('token')
        }
    });

    dispatch({
        type: GROUPS_GET_LIST,
        groups
    });
    dispatch(setGroupsProcessing(false));
}).catch((error) =>
    dispatch(groupsSetMessage(error))
);

export const groupsSetMessage = (message) => {
    if (message instanceof Error) {
        return {
            type: GROUPS_SET_MESSAGE,
            message: {
                status: false,
                message: message.toString()
            }
        };
    }
    return {
        type: GROUPS_SET_MESSAGE,
        message
    };
};
