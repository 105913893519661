import { uniq, find } from 'lodash';
import NETWORKS from "./networkTargeting";

export default class FieldData {
    subAccounts = (subAccountsList) => {
        const {gemini, outbrain, taboola} = subAccountsList;
        return [
            {
                label: 'Gemini Sub Account',
                value: gemini,
                network: NETWORKS.GEMINI,
                accountList: gemini,
                key: 'subAccounts.gemini'
            },
            {
                label: 'Outbrain Sub Account',
                value: outbrain,
                network: NETWORKS.OUTBRAIN,
                accountList: outbrain,
                key: 'subAccounts.outbrain'
            },
            {
                label: 'Taboola Sub Account',
                value: taboola,
                network: NETWORKS.TABOOLA,
                accountList: taboola,
                key: 'subAccounts.taboola'
            },
        ];
    };

    platformTargeting = (platformTargeting) => [
        {name:"all", value: platformTargeting.all, label:'All'},
        {name:"desk", value: platformTargeting.desk, label: 'Desktop'},
        {name:"phone", value: platformTargeting.phone, label: 'Phone'},
        {name:"tablet", value: platformTargeting.tablet, label: 'Tablet'}
    ];

    campaignDetails = (showHideGemini, validation, networks) => [
        {
            name: 'campaign_name', label: 'Name Your Campaign', key: 'campaignName',
            callback: validation.campaignName,
            tooltip: "Maximus will add a network abbreviation, country targeting code, " +
            "and platform targeting code to your campaign name. EX: O_TestCampaignName_US_DESK",
        },
        {
            name: 'publisher_targeting_type', label: 'Site Rule Set', key: 'publisherTargeting',
            callback: validation.publisherTargetingType(networks), conditional: showHideGemini,
            tooltip: 'You can use the site rule set field to declare ' +
            'either whitelist or blacklist sites that representatives will apply to your campaign.'
        }
    ];

    osTargeting = {
      mac: { value: 'Mac_OS_X', label: 'Mac OS X' },
      linux: { value: 'Linux', label: 'Linux' },
      windows: { value: 'Windows', label: 'Windows' },
      ios: { value: 'iOS', label: 'iOS' },
      android: { value: 'Android', label: 'Android' },
      all: { value: 'All', label: 'All' },
    };

    modifyTaboolaOSTargeting = (platformTargeting) => {
      const platformCategories = {
        all: ['windows', 'mac', 'linux', 'ios', 'android', 'all'],
        desk: ['mac', 'linux', 'windows', 'all'],
        phone: ['ios', 'android', 'all'],
        tablet: ['ios', 'android', 'all'],
      };
      let osList = ['all'];
      Object.keys(platformTargeting).forEach((platform) => {
        if (platformTargeting[platform]) {
          osList = [...osList, ...platformCategories[platform]];
        }
      });
      osList = uniq(osList);
      return osList.map(os => this.osTargeting[os]);
    };

  modifyOutbrainOSTargeting = (platformTargeting, deviceOptions) => {
    const platformCategories = {
      all: ['windows', 'macos', 'ios', 'android', 'all'],
      desk: ['macos', 'windows', 'all'],
      phone: ['ios', 'android', 'all'],
      tablet: ['ios', 'android', 'all'],
    };
    let osList = ['all'];
    Object.keys(platformTargeting).forEach((platform) => {
      if (platformTargeting[platform]) {
        osList = [...osList, ...platformCategories[platform]];
      }
    });
    osList = uniq(osList);
    return osList.map((os) => {
      const option = find(deviceOptions, ({ name }) => name.toLowerCase() === os);
      return option || null;
    }).filter(i => i);
  };

    marketingObjective = [
      { value: 'BRAND_AWARENESS', label: 'Brand awareness' },
      { value: 'LEADS_GENERATION', label: 'Lead generation' },
      { value: 'ONLINE_PURCHASES', label: 'Online purchases' },
      { value: 'DRIVE_WEBSITE_TRAFFIC', label: 'Drive website traffic' },
      { value: 'MOBILE_APP_INSTALL', label: 'Mobile app installs' },
    ];

    geminiObjective = [
      { value: '1', label: 'Visit Web' },
      /* { value: '2', label: 'Visit Offer' },
      { value: '3', label: 'Promote Brand' },
      { value: '4', label: 'Install App' }, */
      { value: '5', label: 'Mail Sponsored' },
      // { value: '6', label: 'Re-engage App' },
    ];
}
