import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tabs, Tab, withStyles, Grid, AppBar, IconButton } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import AdminSettingsComponent from './AdminSettingsComponent.js';
import logo from '../assets/images/Maximus_Logo2.png';
import NetworkRequest from '../utilities/NetworkRequest';
import { settings } from '../Settings';
import { adminUser } from '../utilities/FeatureFlagHelper';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {updateNavigationTab} from "../actions/navigation";
import Notifications from './Notifications';
import LastUpdated from './common/LastUpdated';

const styles = (() => ({
  tabsIndicator: {
    backgroundColor: '#FFFFFF',
  },
}));

const routeMapping = {
  0: <Redirect to="/dashboard" />,
  1: <Redirect to="/manage" />,
  2: <Redirect to="/add_campaign" />,
  3: <Redirect to="/settings" />,
  4: <Redirect to="/logout" />,
};

const urlMapping = {
  test_details: -1,
  admin_spend_report: -1,
  campaign: -1,
  dashboard: 0,
  manage: 1,
  clone: 2,
  add_campaign: 2,
  settings: 3,
  faqs: 4,
};

class MaximusToolbar extends React.Component {
  state = {
    fireRedirect: 0,
    showAdminDialog: false,
    userWarning: '',
  };

  componentWillMount() {
    this.setUrlTab();
    this.checkLoggedInStatus();
  }

  setUrlTab = () => {
    let locationURL = window.location.href.replace('http://', '');
    locationURL = locationURL.replace('https://', '');
    const locationPage = locationURL.split('/')[1];
    if (urlMapping[locationPage]) {
      this.props.updateNavigationTab(urlMapping[locationPage]);
    } else {
      this.props.updateNavigationTab(0);
    }
  };

  checkLoggedInStatus = (reload = false) => {
    NetworkRequest.send(
      settings.checkLoggedUser, {},
      ({ data }) => {
        if (reload) {
          window.location.reload();
        } else {
          this.setState({ userWarning: data.status ? data.message : '' });
        }
      },
    );
  };

  openSupportPage = () => {
    const win = window.open('https://help.maximusnative.io/getting-started/welcome-to-maximus-support', '_blank');
    win.focus();
  };

  handleChange = (event, index) => {
    this.setState(
      { fireRedirect: 1 },
      () => this.changeTabIndex(index),
    );
  };

  showAdminDialog = () =>
    this.setState({ showAdminDialog: true });

  closeAdminDialog = () =>
    this.setState({ showAdminDialog: false });

  changeTabIndex = index =>
    this.props.updateNavigationTab(index);

  renderTab = tabName => (
    <Tab
      style={{ margin: '0 auto', minWidth: 'auto', color: 'white' }}
      classes={styles.tab}
      label={tabName}
    />);

  redirectHandler = () => {
    const { fireRedirect } = this.state;
    const { tabIndex } = this.props;
    if (fireRedirect === 1) {
      return routeMapping[tabIndex];
    }
  };

  renderTabs = () => (
    <Tabs
      classes={{ indicator: this.props.classes.tabsIndicator }}
      value={this.props.tabIndex}
      onChange={this.handleChange}
      variant="scrollable"
      scrollButtons="off"
      textColor="inherit"
    >
      {this.renderTab('Dashboard')}
      {this.renderTab('Manage Campaigns')}
      {this.renderTab('Add Campaign')}
      {this.renderTab('Settings')}
      {this.renderTab('Log Out')}
    </Tabs>);

  renderSupportButton = () => (
    <IconButton
      onClick={this.openSupportPage}
    >
      <Help style={{ color: 'white' }} />
    </IconButton>
  );

  renderAppBar = () => (
    <AppBar style={{ backgroundColor: '#333333' }} position="static" color="default">
      <Grid container justify="center" alignItems="center">
        <Grid
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: adminUser() ? 'pointer' : 'auto',
          }}
          item
          sm={2}
          xs={12}
          onClick={adminUser() ? this.showAdminDialog : () => {}}
        >
          <img alt="" src={logo} width={150} />
        </Grid>
        <Grid style={{ marginLeft: '-1rem' }} item sm={8} xs={12}>
          {this.renderTabs()}
        </Grid>
        <Grid style={{ marginLeft: '-1rem', display: 'flex', justifyContent: 'flex-end' }} item sm={2} xs={12}>
          {this.renderSupportButton()}
          <LastUpdated />
          {this.props.userSettings.notifications && <Notifications />}
        </Grid>
      </Grid>
    </AppBar>);

  render() {
    const { userWarning, showAdminDialog } = this.state;
    return (
      <Grid id="maximus-navigation" container justify="center">
        <Grid item xs={12}>
          {userWarning &&
          <div className="adminFlag">
            {userWarning} <a href=" " onClick={this.AdminSettingsComponent.handleCancelUser}>( Stop )</a>
          </div>}
          <AdminSettingsComponent
            open={showAdminDialog}
            onRef={ref => this.AdminSettingsComponent = ref}
            checkLoggedInStatus={this.checkLoggedInStatus}
            closeDialog={this.closeAdminDialog}
            userWarning={userWarning}
          />
          {this.renderAppBar()}
        </Grid>
        {this.redirectHandler()}
      </Grid>
    );
  }
}

MaximusToolbar.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(state => ({
  tabIndex: state.navigation.tabIndex,
  userSettings: state.adSetup.userSettings,
}), dispatch =>
  bindActionCreators({
    updateNavigationTab,
  }, dispatch))(withStyles(styles)(MaximusToolbar));