const TABOOLA = 1;
const OUTBRAIN = 2;
const REVCONTENT = 3;
const CONTENTAD = 4;
const GEMINI = 5;
const BAIDU = 6;
const networks = {
  TABOOLA,
  OUTBRAIN,
  REVCONTENT,
  CONTENTAD,
  GEMINI,
  BAIDU,
};

const hasNetwork = (userNetworks, network) => userNetworks.includes(network);
const parseNetwork = network => parseInt(network, 10);

export const hasTaboola = userNetworks => hasNetwork(userNetworks, TABOOLA);
export const hasOutbrain = userNetworks => hasNetwork(userNetworks, OUTBRAIN);
export const hasRevcontent = userNetworks => hasNetwork(userNetworks, REVCONTENT);
export const hasContentad = userNetworks => hasNetwork(userNetworks, CONTENTAD);
export const hasGemini = userNetworks => hasNetwork(userNetworks, GEMINI);
export const hasBaidu = userNetworks => hasNetwork(userNetworks, BAIDU);

export const isTaboola = network => parseNetwork(network) === TABOOLA;
export const isOutbrain = network => parseNetwork(network) === OUTBRAIN;
export const isRevcontent = network => parseNetwork(network) === REVCONTENT;
export const isContentad = network => parseNetwork(network) === CONTENTAD;
export const isGemini = network => parseNetwork(network) === GEMINI;
export const isBaidu = network => parseNetwork(network) === BAIDU;

export const geminiGroupToTitleMap = {
  'GROUP_1_A': 'Group 1-A',
  'GROUP_1_B': 'Group 1-B',
  'GROUP_2_A': 'Group 2-A',
  'GROUP_2_B': 'Group 2-B',
  'GROUP_3_A': 'Group 3-A',
  'GROUP_3_B': 'Group 3-B'
};

export const networksNames = {
  [TABOOLA]: 'Taboola',
  [OUTBRAIN]: 'Outbrain',
  [REVCONTENT]: 'RevContent',
  [CONTENTAD]: 'Content.Ad',
  [GEMINI]: 'Gemini',
  [BAIDU]: 'Baidu',
};

export default networks;
