import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from '@material-ui/core/Button';
import _noop from 'lodash/noop';
import maximusPalette from '../../MaximusColor';

class ConfirmDialog extends Component {
    static propTypes = {
      saveButtonText: PropTypes.string,
      cancelButtonText: PropTypes.string,
      contentAlign: PropTypes.oneOf(['center', 'left', 'right']),
      footerAlign: PropTypes.oneOf(['center', 'left', 'right']),
      shouldRenderAction: PropTypes.bool,
      onRequestSave: PropTypes.func,
      onRequestClose: PropTypes.func,
      children: PropTypes.node,
      toggleOpenModal: PropTypes.func,
    };

    static defaultProps = {
      saveButtonText: 'Save',
      cancelButtonText: 'Cancel',
      contentAlign: 'center',
      footerAlign: 'right',
      shouldRenderAction: true,
      onRequestSave: _noop,
      onRequestClose: _noop,
      children: null,
      toggleOpenModal: _noop,
    };

    onSuccess = () => Promise
      .resolve(this.props.onRequestSave())
      .then((close = false) => this.props.toggleOpenModal(close));

    onCancel = () => Promise
      .resolve(this.props.onRequestClose())
      .then(() => this.props.toggleOpenModal(false));

    render() {
      const {
        children,
        saveButtonText,
        cancelButtonText,
        contentAlign,
        footerAlign,
      } = this.props;

      return (
        <div>
          <div className="maximus-dialog-content" style={{ textAlign: contentAlign }}>
            <div>{ children }</div>
          </div>

          {this.props.shouldRenderAction && (
            <div className={cn('maximus-dialog-footer', footerAlign)}>
              <Button
                onClick={this.onCancel}
                variant="contained"
                disabled={this.props.disabled || false}
                style={{
                    backgroundColor: maximusPalette.darkCancel,
                    color: 'white',
                    minWidth: '40%',
                    margin: '0 5%',
                }}
              >
                {cancelButtonText}
              </Button>
              <Button
                onClick={this.onSuccess}
                variant="contained"
                disabled={this.props.disabled || false}
                style={{
                    backgroundColor: maximusPalette.submit,
                    color: 'white',
                    minWidth: '40%',
                    margin: '0 5%',
                }}
              >
                {saveButtonText}
              </Button>
            </div>
          )}
        </div>
      );
    }
}

export default ConfirmDialog;
