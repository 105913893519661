import {currency, currencyMultiplier} from "./FeatureFlagHelper";

export const CURRENCY = {
  dollar: {
    calculation: 1,
    symbol: '$'
  },
  yen: {
    calculation: currencyMultiplier(),
    symbol: '¥'
  },
  yuan: {
    calculation: currencyMultiplier(),
    symbol: '¥'
  },
};

export const currencyValidation = (cpc, fixed = 2) => {
  if(!CURRENCY[currency()]) {
    return cpc
  }
  return (cpc * CURRENCY[currency()].calculation).toFixed(fixed);
};