import { UPDATE_TABS, REFRESH_DATA, UPDATE_REFRESH_DATA_AT } from '../actions/navigation';

const initialState = {
  tabIndex: 2,
  refreshData: false,
  updatedAt: new Date(),
};

export const navigation = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TABS:
      return {
        ...state,
        tabIndex: action.tabIndex,
      };
    case REFRESH_DATA:
      return {
        ...state,
        refreshData: action.value,
      };
    case UPDATE_REFRESH_DATA_AT:
      return {
        ...state,
        updatedAt: new Date(),
      };
    default:
      return state;
  }
};
