import React, {Component} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Grid, Typography, Dialog, Button } from '@material-ui/core';
import {DialogActions, DialogTitle,} from '@material-ui/core';
import moment from 'moment-timezone';

// utilities
import {getFeatureFlags, checkPrimaryUser, timezone} from './utilities/FeatureFlagHelper';
import {redirectNewUser} from "./utilities/redirectNewUsers";
import { getUserSettings as userSettingsRequest } from './dataSource/campaign';
import { loadUserSettings } from './components/AdSetup/actions/adSetup';

// components
import MaximusRoute from './components/MaximusRoute';
import MaximusPublicRoute from './components/MaximusPublicRoute';
import {settings} from './Settings';

// styles
import './App.css'
import './assets/css/appCss.css'
import LogRocket from 'logrocket';
import AlertContainer from "./components/MaximusAlert/AlertContainer";
import NetworkRequest from './utilities/NetworkRequest';
import { updateDatagridCustomization } from './actions/table';

class Maximus extends Component {

  constructor(props) {
    super(props);
    const currentUrlPath = window.location.pathname;
    this.state = {
      authenticated: null,
      routeChoice: null,
      openAlertDialog: false,
      currentUrlPath,
      timeZone: timezone(),
      maximusVersion: '0.3.0',
      currentTimeEST: this.getTime(timezone())
    };

    this.authOrNotAuth();
    getFeatureFlags();
    checkPrimaryUser();
    setInterval(() => {
      getFeatureFlags();
      checkPrimaryUser();
    }, 30000);

    if (currentUrlPath !== '/login' && !this.state.authenticated) {
      this.checkIfUserIsLoggedIn();
    }

    this.initializeLogRocket();
    setInterval(this.checkIfUserIsLoggedIn, 30000);
  }

  componentWillMount() {
    const currentUrlPath = window.location.pathname;
    if (currentUrlPath !== '/login') {
      redirectNewUser(currentUrlPath)
    }

  }

  initializeLogRocket = () => {
    if (process.env.NODE_ENV === 'production') {
      LogRocket.init('cegmbr/maximus');

      const email = localStorage.getItem('email');

      LogRocket.identify(email, {
        email,
        environment: process.env.NODE_ENV,
        subscriptionType: 'pro'
      });
    }
  }

  componentDidMount() {
    this.getAuthAndUserSettings();
    this.setMaximusVersionToLocalStorage();
    this.getTableDefaults();
    setInterval(() => {
      if (this.state.timeZone !== timezone()) {
        this.setState({
          timeZone: timezone()
        })
      }
    }, 5000)

    setInterval(() => {
        this.setState({
          currentTimeEST: this.getTime(this.state.timeZone)
        })
    }, 60000)
  }

  getTableDefaults = async () => {
    if (localStorage.getItem('token')) {
      const { data: {datagrid_customization: dataGridCustomization }} = await NetworkRequest.post(settings.getUserSettings, {
        action: 'check_user_setting',
        setting_name: 'datagrid_customization'
      }).catch(console.error);
      if (dataGridCustomization && typeof dataGridCustomization === 'string') {
        try {
          this.props.updateTable(JSON.parse(dataGridCustomization));
        } catch {
          // eslint-disable-next-line no-console
          console.error('error', dataGridCustomization);
        }
      }
    }
  };

  async getAuthAndUserSettings() {
    await this.getUserSettings();
    this.getAuthData();
  }

  componentDidUpdate(prevProps) {
    const {location} = this.props;

    if (location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    // console.log("ROUTE CHANGED");
  }

  refreshMaximus = () => window.location.reload(true);

  getTime = (timeZone) => moment().tz(timeZone || 'America/New_York').format('MM/DD/YYYY h:mm A');


  getAuthData = () => {

    const {authenticated} = this.state;

    if (!authenticated) {
      if (localStorage.getItem('token') === null) {
        this.setState({'authenticated': false, routeChoice: this.authOrNotAuth('public')});

      } else {
        axios.post(settings.checkToken, {
          token: localStorage.getItem('token')
        }).then((response) => {
          if (response.data.status === false) {
            // TODO We aren't logged in??
            // console.log('status is false');
            this.clearTokenData();
            this.setState({'authenticated': false, routeChoice: this.authOrNotAuth('public')});
          } else {
            this.setState({'authenticated': true, routeChoice: this.authOrNotAuth('private')});
          }
        }).catch(function (error) {
          // this.setState({'authenticated': false});
          // console.log(error);
        });
      }
    }
  }


  setMaximusVersionToLocalStorage = async () => {
    const {userVersionURL} = settings;
    const {maximusVersion} = this.state;
    const params = {
      action: 'get_user_version',
      token: localStorage.getItem('token')
    };

    try {
      const {data: {version}} = await axios.post(userVersionURL, {params});

      if (!version) {
        this.updateMaximusVersionOnServer();
      } else if (maximusVersion !== version) {
        this.updateMaximusVersionOnServer().then(() => {
          this.setState({
            openAlertDialog: true
          });
        })
      }
    } catch (err) {

    }
  };

  updateMaximusVersionOnServer = async () => {
    const {maximusVersion} = this.state;
    const {userVersionURL} = settings;
    const params = {
      action: 'update_user_version',
      token: localStorage.getItem('token'),
      version: maximusVersion
    };

    try {
      await axios.post(userVersionURL, {params});
    } catch (err) {

    }
  };

  checkIfUserIsLoggedIn = () => {
    const {authenticated, currentUrlPath} = this.state;

    if (authenticated) {
      axios.post(settings.checkToken, {
        token: localStorage.getItem('token')
      }).then((response) => {
        if (response.data.status === false) {
          this.clearTokenData();
          window.location.assign('/login');
        }
      }).catch(function (error) {

      });
    } else {
      if (currentUrlPath !== '/login' && (!!authenticated || !localStorage.getItem('token'))) {
        this.clearTokenData();
        window.location.assign('/login');
      }
    }
  }

  clearTokenData = () => {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }
    if (localStorage.getItem('aup')) {
      localStorage.removeItem('aup');
    }
  }


  authOrNotAuth = route => {
    if (route === "private") {
      return (<MaximusRoute getAuthData={this.getAuthData} />)
    } else {
      return (<MaximusPublicRoute getAuthData={this.getAuthData}/>)
    }
  }

  getUserSettings = async () => {
    const data = await userSettingsRequest().catch(console.error);
    if(!data) return;
    this.props.loadUserSettings(data);
  };

  render() {
    const {
      routeChoice, timeZone, openAlertDialog, currentTimeEST, authenticated,
    } = this.state;
    let locationURL = window.location.href.replace('http://', '');
    locationURL = locationURL.replace('https://', '');
    locationURL = locationURL.split('/')[1];

    return (
      <Grid container>
        <Grid item xs={12}>
          {routeChoice}
        </Grid>
        {(locationURL === 'dashboard' || locationURL === 'manage' || locationURL === 'campaign') &&
        <Grid container justify="flex-start" style={{marginTop: 7, marginLeft: 40}}>
          <Typography className={'captionText'} variant="caption" gutterBottom>
            Reporting is in {timeZone || 'America/New_York'}. Current time is {currentTimeEST}.
          </Typography>
        </Grid>
        }

        <Dialog style={{textAlign: 'center'}} open={openAlertDialog} onClose={this.refreshMaximus}>
          <DialogTitle>
            A new version of Maximus is available
          </DialogTitle>
          <DialogActions className="refreshButtons">
            <Button style={{backgroundColor: 'lightgray'}} onClick={this.refreshMaximus} color="primary">
              UPDATE
            </Button>
          </DialogActions>
        </Dialog>
        {authenticated && <AlertContainer />}
      </Grid>
    )
  }
}

export default connect(() => {}, dispatch =>
  bindActionCreators({
    loadUserSettings,
    updateTable: updateDatagridCustomization,
  }, dispatch))(Maximus);
