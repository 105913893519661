import {UPDATE_SITE_BIDS, SET_GEMINI_CLONED_BIDS} from '../actions/actions';

const setStateVal = (value, error = false, message = '') => {
  return {error, message, value};
};

export const initialState = {
  gemini_site_bids: setStateVal([]),
  gemini_cloned_bids: setStateVal([]),
};

export const SiteBids = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SITE_BIDS:
      return {
        ...state,
        gemini_site_bids: setStateVal(action.value, action.valid, action.message)
      };
    case SET_GEMINI_CLONED_BIDS:
      return {
        ...state,
        gemini_cloned_bids: setStateVal(action.value)
      };
    default:
      return state;
  }
};