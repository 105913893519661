import React, { Component } from 'react';
import './SuccessCheckmark.css';

class SuccessCheckmark extends Component {
    state={
      circleClass: 'circle-loader',
      checkmarkClass: 'checkmark draw',
      displayCheckmark: 'none',
    };

    componentDidMount() {
      // display checkmark animation after 1 second
      setInterval(() => {
        this.setState({
          circleClass: 'circle-loader load-complete',
          displayCheckmark: 'block',
        });
      }, 1000);
    }

    render() {
      const {
        circleClass,
        checkmarkClass,
        displayCheckmark,
      } = this.state;

      return (
        <div className={circleClass}>
          <div className={checkmarkClass} style={{ display: displayCheckmark }} />
        </div>
      );
    }
}

export default SuccessCheckmark;
