export const UPDATE_SITE_BIDS = 'UPDATE_SITE_BIDS';
export const SET_GEMINI_CLONED_BIDS = 'SET_GEMINI_CLONED_BIDS';

export const updateSiteBids = (value, valid = true, message = '') => {
  return {
    type: UPDATE_SITE_BIDS,
    value,
    valid,
    message
  };
}
export const setGeminiClonedBids = (value) => {
  return {
    type: SET_GEMINI_CLONED_BIDS,
    value,
  };
}