import {currencyValidation} from "../../../utilities/currencyHelper";
import {checkMaximusLite} from "../../../utilities/FeatureFlagHelper";

const setStateVal = (value, error = false, message = '') => {
  return {error, message, value};
};

const liteUserDefault = (defaultVal, liteVal = false) => checkMaximusLite() ? liteVal : defaultVal;

export const initialState = {
  status                  : setStateVal('true'),
  min_cpc                 : setStateVal(currencyValidation(0.10)),
  max_day_loss            : setStateVal(100),
  day_loss_active         : setStateVal(true),
  act_spend_active        : setStateVal(false),
  max_max_day_loss        : setStateVal(250),
  protect_profit_slow     : setStateVal(500),
  protect_profit_stop     : setStateVal(1000),
  protect_profit_active   : setStateVal(false),
  budget_reset            : setStateVal(200),
  budget_reset_active     : setStateVal(liteUserDefault(true)),
  min_clicks              : setStateVal(500),
  goal_margin_set         : setStateVal(15),
  goal_margin_active      : setStateVal(liteUserDefault(true)),
  max_cpc                 : setStateVal(currencyValidation(1.00)),
  max_click_recovery      : setStateVal(5),
  recovery_active         : setStateVal(liteUserDefault(true)),
  mins_last_click         : setStateVal(15),
  site_min_cpc            : setStateVal(currencyValidation(0.05)),
  site_max_cpc            : setStateVal(currencyValidation(3.00)),
  site_min_clicks         : setStateVal(50),
  min_epc_block_widget    : setStateVal(currencyValidation(0)),
  min_epc_block           : setStateVal(currencyValidation(0)),
  site_goal_active        : setStateVal(liteUserDefault(true)),
  site_goal_margin        : setStateVal(15),
  ctr_pause               : setStateVal(.05),
  ctr_pause_active        : setStateVal(false),
  preset_name             : setStateVal(''),
  campaign_ignore_act     : setStateVal(true),
  reset_cpc_day_parting   : setStateVal(false),
  site_ignore_act         : setStateVal(false),
  time                    : setStateVal([]),
  lookback_timeframe      : setStateVal(0),
  cpa_goal                : setStateVal(0),
  cpa_goal_active         : setStateVal(liteUserDefault(false, true)),
  movedToNextStep: false,
  automated_presets       : setStateVal(false),
  default_preset          : setStateVal('0'),
  loading_presets         : setStateVal(false),
};

export const fireAndForget = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_FF_CHANGE':
      return {
        ...state,
        [action.state]: action.value
      };
    case 'TURN_ON_CPA_GOAL_ACTIVE':
      return {
        ...state,
        cpa_goal: setStateVal(''),
        cpa_goal_active: setStateVal(true),
        lookback_timeframe: setStateVal(1),
        goal_margin_active: setStateVal(false),
        site_goal_active: setStateVal(false),
      };
    case 'TURN_ON_GOAL_MARGIN_ACTIVE':
      return {
        ...state,
        cpa_goal: setStateVal(0),
        goal_margin_active: setStateVal(true),
        cpa_goal_active: setStateVal(false),
        site_goal_active: setStateVal(true),
      };
    case 'LOAD_FF_VALUES':
      return action.newState;
    case 'RESET_STATE':
      return action.newState;
    case 'MOVE_TO_NEXT_STEP':
      return {
        ...state,
        movedToNextStep: true,
      };
    default:
      return state;
  }
};
