import React from 'react'
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import asyncComponent from './AsyncComponent';
import MaximusToolbar from './Navigation';
import Logout from './Logout';

const AsyncDashboard = asyncComponent(() => import('../containers/Dashboard'));
const AsyncCampaignDetails = asyncComponent(() => import('../containers/CampaignDetails'));
const AsyncSettings = asyncComponent(() => import('./Settings'));
const AsyncManageCampaigns = asyncComponent(() => import('../components/ManageCampaigns/ManageCampaigns'));
const AsyncCloneCampaigns = asyncComponent(() => import('../containers/CloneCampaign'));
const AsyncAddCampaign = asyncComponent(() => import('../containers/AddCampaign'));
const AsyncAsyncAdminSpendReport = asyncComponent(() => import('../containers/AdminSpendReport'));
const AsyncNetworkSetup = asyncComponent(() => import('../containers/NetworkSetup'));
const AsyncAdminDashboard = asyncComponent(() => import('../containers/AdminDashboard'));
const AsyncAdmin = asyncComponent(() => import('../containers/Admin'));

const hideForWelcome = window.location.pathname !== '/welcome';

const MaximusRoute = ({ component, ...props }) => (
  <Router>
    <Grid>
      {hideForWelcome && <MaximusToolbar isAuth={props.isAuth}/>}
      <Route path="/campaign/:id" component={AsyncCampaignDetails}/>
      <Route path="/manage" component={AsyncManageCampaigns}/>
      <Route path="/clone/:id" component={AsyncCloneCampaigns}/>
      <Route path="/settings" component={AsyncSettings}/>
      <Route path="/dashboard" component={AsyncDashboard}/>
      <Route path="/add_campaign" component={AsyncAddCampaign}/>
      <Route path="/admin_spend_report" component={AsyncAsyncAdminSpendReport}/>
      <Route exact path="/welcome" component={AsyncNetworkSetup}/>
      <Route exact path="/logout" render={() => <Logout getAuthData={props.getAuthData}/>}/>
      <Route exact path="/" component={AsyncDashboard}/>
      <Route exact path="/admin_dashboard" component={AsyncAdminDashboard} />
      <Route exact path="/admin_reporting" component={AsyncAdmin} />
    </Grid>
  </Router>
);
export default MaximusRoute
