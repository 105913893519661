import { USERS_GET_LIST, USERS_SET_MESSAGE, USERS_SET_PROCESSING, USERS_GET_MASTER_USERS } from '../actions/users';

const initialState = {
  list: [],
  processing: false,
  message: {
    status: true,
    message: '',
  },
  masterUsers: [],
};

export const users = (state = initialState, action) => {
  switch (action.type) {
    case USERS_SET_MESSAGE:
      return {
        ...state,
        message: action.message,
      };
    case USERS_SET_PROCESSING:
      return {
        ...state,
        processing: action.processing,
      };
    case USERS_GET_LIST:
      return {
        ...state,
        list: action.users,
      };
    case USERS_GET_MASTER_USERS:
      return {
        ...state,
        masterUsers: action.users,
      };
    default:
      return state;
  }
};
