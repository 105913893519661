import {
  UPDATE_CAMPAIGN_VALUE,
  RESET_CAMPAIGN_DETAILS,
  UPDATE_DATE_RANGE,
  LOAD_GROUPS,
  LOAD_USERS,
  UPDATE_RULES_STATUS,
  UPDATE_HIDE_BLOCKED_SITES_STATUS,
} from '../Actions/CampaignDetails';

export const initialState = {
  load_error: false,
  campaign_budget: null,
  campaign_cpc: null,
  campaign_name: null,
  network_id: null,
  network: null,
  networks: [],
  campaign_id: null,
  tracking_type: null,
  items_count: null,
  campaignArchiveStatus: null,
  budgetIncreaseMode: null,
  fnfMinClicksReached: null,
  siteBlockLimitReached: null,
  geminiSiteBlockLimitReached: null,
  maxBidsOutbrain: null,
  CPAGoalReached: null,
  CPAGoalReachedMessage: null,
  linkTestingNotification: null,
  linkTestingNotificationMessage: null,
  linkTestingClicks: 0,
  campaign_images: null,
  link: null,
  link_uuid: null,
  inactiveStatus: null,
  bidsCount: null,
  blockedDomainsCount: 0,
  display_url: null,
  fireAndForgetFlag: null,
  crossRoadsStatus: null,
  start_date: null,
  end_date: null,
  date_range: null,
  rulesStatus: false,
  campaignStatusText: '',
  week_days: 'all',
  openDrawer: '',
  outbrainSegments: [],
  groups: [],
  users: [],
  hideBlockedSites: 0,
  testingLinksStatus: false,
  automatedRoutesStatus: false,
  automatedRoutesPercentageCVR: 0,
  automatedRoutesPercentageCTR: 0,
  automatedRoutesClicks: 0,
  automatedRoutesMessage: '',
  isBaidu: false,
};

export const campaignDetails = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CAMPAIGN_VALUE:
      return {
        ...state,
        [action.state]: action.value
      };
    case UPDATE_DATE_RANGE:
      return {
        ...state,
        start_date: action.start_date,
        end_date: action.end_date,
        date_range: action.date_range,
        week_days: action.week_days || state.week_days,
      };
    case LOAD_USERS:
      return {
        ...state,
        users: action.users
      };
    case LOAD_GROUPS:
      return {
        ...state,
        groups: action.groups
      };
    case UPDATE_RULES_STATUS:
      return {
        ...state,
        rulesStatus: action.status
      };
    case UPDATE_HIDE_BLOCKED_SITES_STATUS:
      return {
        ...state,
        hideBlockedSites: action.value,
      };
    case RESET_CAMPAIGN_DETAILS:
      return action.newState;
    default:
      return state;
  }
};
