import { mapKeys } from 'lodash';
import NetworkRequest from '../utilities/NetworkRequest';
import { settings } from '../Settings';

export const GLANCE_GET_DATA = 'GLANCE_GET_DATA';
export const GLANCE_SET_PROCESSING = 'GLANCE_SET_PROCESSING';

export const setGlanceProcessing = value => ({
  type: GLANCE_SET_PROCESSING,
  value,
});

export const getGlanceData = params => async (dispatch) => {
  dispatch(setGlanceProcessing(true));
  try {
    const paramsFinal = {
      ...params,
      timeframe: params.date_range,
    };
    const { data } = await NetworkRequest.post(`${settings.dashboardURL}get_overview_stats`, paramsFinal);
    setGlanceData(dispatch, data);
    dispatch(setGlanceProcessing(false));
  } catch (e) {
    console.error(e.message);
    dispatch(setGlanceProcessing(false));
  }
};

const setGlanceData = (dispatch, data) =>
  dispatch({
    type: GLANCE_GET_DATA,
    value: mapKeys(data[0], mapGlanceDataKeys),
  });

const mapGlanceDataKeys = (value, key) => {
  if (key === 'profit_pct') {
    return 'profitPct';
  }
  if (key === 'estimated_spent') {
    return 'spent';
  }
  return key;
};
