export const UPDATE_TABS = 'UPDATE_TABS';
export const REFRESH_DATA = 'REFRESH_DATA';
export const UPDATE_REFRESH_DATA_AT = 'UPDATE_REFRESH_DATA_AT';

export const updateNavigationTab = tabIndex => ({
  type: UPDATE_TABS,
  tabIndex,
});

export const refreshData = (value = true) => ({
  type: REFRESH_DATA,
  value,
});

export const updateRefreshedAt = () => ({
  type: UPDATE_REFRESH_DATA_AT,
});
