export const setStateVal = (value = '', error = false, message = '') => {
  return {value, error, message};
};

const loadDefaultLinkValues = (url, error, message) => {
  return {
    url: setStateVal(url, error, message),
    weight: setStateVal(100),
  };
};

export const getHighestValueForMapping = (object) => {
  return Object.keys(object).length === 0 ? 0 : Math.max.apply(null, Object.keys(object)) + 1;
};

export const initialState = {
  modes: setStateVal('weighted'),
  landingPage: setStateVal(),
  weightedLinks: {
    0: {
      url: setStateVal(),
      weight: setStateVal(100),
    },
  },
  advancedTracking: false,
  advancedTrackingLinks: {
    weightedLinks: {
      0: {
        url: setStateVal(),
        weight: setStateVal(100),
      },
    },
  },
};

export const createLink = (state = initialState, action) => {
  switch (action.type) {
    case 'HANDLE_LINKS_CHANGE':
      return {
        ...state,
        [action.state]: action.value
      };
    case 'HANDLE_ADVANCED_TRACKING_CHANGE':
      return {
        ...state,
        advancedTrackingLinks: {
          ...state.advancedTrackingLinks,
          [action.state]: action.value,
        },
      };
    case 'RESET_LINKS_STATE':
      return {...initialState};

    case 'LOAD_LINK_VALUES':
      return action.newState;

    case 'ADD_LINK':
      const amountOfLinks = getHighestValueForMapping(state.weightedLinks);
      return {
        ...state,
        weightedLinks: Object.assign({}, state['weightedLinks'], {
            [amountOfLinks]: loadDefaultLinkValues(action.value, action.error, action.message),
          }
        )
      };
    case 'ADD_OFFER_LINK':
      const amountOfOfferLinks =
        getHighestValueForMapping(state.advancedTrackingLinks.weightedLinks);
      return {
        ...state,
        advancedTrackingLinks: {
          ...state.advancedTrackingLinks,
          weightedLinks: {
            ...state.advancedTrackingLinks.weightedLinks,
            [amountOfOfferLinks]: loadDefaultLinkValues(action.value, action.error, action.message),
          },
        },
      };

    case 'REMOVE_LINK':
      const links = Object.assign({}, state.weightedLinks);
      delete links[action.key];
      return {
        ...state,
        weightedLinks: links
      };
    case 'REMOVE_OFFER_LINK':
      const weightedLinks = { ...state.advancedTrackingLinks.weightedLinks };
      delete weightedLinks[action.key];
      return {
        ...state,
        advancedTrackingLinks: {
          ...state.advancedTrackingLinks,
          weightedLinks,
        },
      };

    case 'CHANGE_LINK':
      return {
        ...state,
        weightedLinks: Object.assign({}, state['weightedLinks'], {
            [action.key]: Object.assign({}, state['weightedLinks'][action.key],
              {
                [action.state]: action.value
              }
            ),
          }
        )
      };

    case 'CHANGE_OFFER_LINK':
      return {
        ...state,
        advancedTrackingLinks: {
          ...state.advancedTrackingLinks,
          weightedLinks: {
            ...state.advancedTrackingLinks.weightedLinks,
            [action.key]: {
              ...state.advancedTrackingLinks.weightedLinks[action.key],
              [action.state]: action.value,
            },
          },
        },
      };

    default:
      return state;
  }
};