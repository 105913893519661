import {initialState} from "../reducers/geminiSettings";
import { isNil } from 'lodash';

export const HANDLE_GEMINI_CHANGE = 'HANDLE_GEMINI_CHANGE';

const setStateVal = (value, error = false, message = '') => {
  return {error, message, value};
};

export const handleChange = (state, value, valid = true, message = '') => {
  return {
    state,
    type: HANDLE_GEMINI_CHANGE,
    value: {
      value,
      error: !valid,
      message,
    }
  };
};

export const loadGeminiValues = (fieldValues) => {
  const newState = {...initialState};

  Object.keys(newState).map(stateName => {
    let value = fieldValues[stateName];

    newState[stateName] = setStateVal(!isNil(value) ? value : newState[stateName].value);
    return true;
  });

  return {
    type: 'LOAD_GEMINI_VALUES',
    newState
  };
};

export const resetGeminiSettings = () => {
  const newState = {...initialState};
  return {
    type: "RESET_GEMINI_SETTINGS",
    newState
  };
};

export const setGeminiFFGroup = (value) => {
  return {
    type: "SET_GEMINI_FF_GROUP",
    value
  };
};