import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from '@material-ui/core/Button';
import _noop from 'lodash/noop';
import maximusPalette from '../../MaximusColor';

class ErrorDialog extends Component {
    static propTypes = {
      confirmButtonText: PropTypes.string,
      contentAlign: PropTypes.oneOf(['center', 'left', 'right']),
      footerAlign: PropTypes.oneOf(['center', 'left', 'right']),
      shouldRenderAction: PropTypes.bool,
      onRequestClose: PropTypes.func,
      children: PropTypes.node,
      toggleOpenModal: PropTypes.func,
    };

    static defaultProps = {
      confirmButtonText: 'Close',
      contentAlign: 'center',
      footerAlign: 'right',
      shouldRenderAction: true,
      onRequestClose: _noop,
      children: null,
      toggleOpenModal: _noop,
    };

    onConfirm = () => Promise
      .resolve(this.props.onRequestClose())
      .then(() => this.props.toggleOpenModal(false));

    render() {
      const {
        children,
        confirmButtonText,
        contentAlign,
        footerAlign,
      } = this.props;

      return (
        <div>
          <div className="maximus-dialog-content" style={{ 'text-align': contentAlign }}>
            <div>{ children }</div>
          </div>

          {this.props.shouldRenderAction && (
            <div className={cn('maximus-dialog-footer', footerAlign)}>
              <Button
                onClick={this.onConfirm}
                variant="contained"
                style={{
                  backgroundColor: maximusPalette.submit,
                  color: 'white',
                  minWidth: '50%',
                  margin: '0 25%',
                }}
              >
                {confirmButtonText}
              </Button>
            </div>
          )}
        </div>
      );
    }
}

export default ErrorDialog;
